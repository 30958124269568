import styled from 'styled-components'

// export const Icon = styled.div`
// height: ${({ height = '50px' }) => height};
// width: ${({ width = '50px' }) => width};
// position: relative;
// object-fit: contain;
// object-position: center;
// background-repeat: no-repeat;
// mask: ${({ src }) => src ? `url('${src}')` : ''};
// background: ${({ isSelected }) => isSelected ? 'var(--formSelectedTileColor)' : 'white'};
// mask-repeat: no-repeat;
// mask-position: center;
// transition: background 0.25s ease-in-out;
// `;

export const Icon = styled.img`
height: ${({ height = '50px' }) => height};
min-height: ${({ minHeight }) => minHeight};
min-width: ${({ minWidth }) => minWidth};
max-height: ${({ maxHeight }) => maxHeight};
max-width: ${({ maxWidth }) => maxWidth};
width: ${({ width = '50px' }) => width};
position: relative;
object-fit: contain;
object-position: center;
background-repeat: no-repeat;
margin: ${({ margin }) => margin};
/* mask: ${({ src }) => src ? `url('${src}')` : ''};
background: ${({ isSelected }) => isSelected ? 'var(--formSelectedTileColor)' : 'white'};
mask-repeat: no-repeat;
mask-position: center; */
transition: background 0.25s ease-in-out;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
            display: ${({ mobileDisplay }) => mobileDisplay};
            z-index: ${({ mobileZindex }) => mobileZindex};
            visibility: ${({ mobileVisibility }) => mobileVisibility};
            width: ${({ mobileWidth }) => mobileWidth};
            height: ${({ mobileHeight }) => mobileHeight};
            min-height: ${({ minHeightMobile }) => minHeightMobile};
            min-width: ${({ minWidthMobile }) => minWidthMobile};
            flex-basis: ${({ mobileFlexBasis }) => mobileFlexBasis};

        }
`;

export const Text = styled.h5`
margin: 0;
text-align: inherit;
position: relative;
font-size: ${({ fontSize = 'inherit' }) => fontSize};
`;

export const SectionImage = styled.img`
position: relative;
height: ${({ height }) => height};
width: ${({ width }) => width};
max-width: ${({maxWidth}) => maxWidth};
max-height: ${({maxHeight}) => maxHeight};
`;

export const Subtext = styled.h4`
font-size: ${({ fontSize }) => fontSize};
font-weight: ${({ fontWeight }) => fontWeight};
margin: 0 0 10px;

`;