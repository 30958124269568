import React from 'react'
import Flexbox from '../Flexbox'


const TextStep = ({ heading, subtext, ...props }) => {
    return (
        <Flexbox column {...props}>
            <h3>{heading}</h3>
            {subtext && <p>{subtext}</p>}
        </Flexbox>
    )
}

export default TextStep