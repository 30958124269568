import React, { lazy } from 'react'
import styled from 'styled-components'

import Calc400 from '../../images/calculator-400.webp'
import Calc400p from '../../images/calculator400.png'
import Calc800 from '../../images/calculator-800.webp'
import Calc from '../../images/aiCalculator.webp'
// const Calc400 = lazy(() => import(('../../images/calculator-400.webp')))
// const Calc400p = lazy(() => import(('../../images/calculator400.png')))
// const Calc800 = lazy(() => import(('../../images/calculator-800.webp')))
// const Calc = lazy(() => import(('../../images/aiCalculator.webp')))


const CalcImageS = styled.picture`
grid-area: image;
width: 100%;
max-width: 500px;
height: auto;
margin: auto;
img{
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: auto;
}
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    max-width: 200px;
    img {
        max-width: 200px;

    }
}
`;

const CalcImage = () => {
    return (
        <CalcImageS>
            <source media="(min-width: 768px)"
                srcSet={Calc800}
                type="image/webp" />
            <source media="(max-width: 500px)"
                srcSet={Calc400}
                type="image/webp" />
            <source media="(min-width: 768px)"
                srcSet={Calc}
                type="image/jpeg" />
            <source media="(max-width: 500px)"
                srcSet={Calc400p}
                type="image/jpeg" />
            <img
                srcSet={`${Calc800} 768w,
            ${Calc400} 500w`}
                sizes="(max-width: 768px) 768px,
            (max-width: 500px) 500px"
                src={Calc}
                width="100%"
                height="auto"
                alt="Settlement Calculator" />
        </CalcImageS>
    )
}

export default CalcImage