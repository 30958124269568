import React, { useState, useEffect, useRef } from 'react'
import Flexbox from '../Flexbox'
import { useNavigate, useLocation } from 'react-router-dom'
import Dropdown from '../Dropdown'
import { GridContainer } from './Navigation.styles'
import GridContainer2 from '../GridContainer'
import eagle from '../../images/eagle.svg'
import attorneyMatch from '../../images/match.svg'
import { fixSvgElementCoordinates } from '../../utils'
import { usePageTracking } from '../../hooks/usePageTracking'
import setCalc from '../../images/setCalcLogo4.svg'
import devCostCalculator from '../../images/devCostCalulatorLogo.svg'
import devCostCalculator2 from '../../images/devCostCalulatorLogo2.png'
import devCostD2 from '../../images/devCostD2.png'
import devCostD from '../../images/devCostD.png'
import devCostFull from '../../images/devCostCalculatorFull.png'
import setCalcBlack from '../../images/setCalcBlack.svg'
import calculator from '../../images/calculator@2x.png'

const Navigation = ({ shouldShow = false, padding = "var(--navBarPadding)" }) => {
    usePageTracking()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location
    const hideLinks = false //pathname === '/'
    const gridContainerRef = useRef(null)
    // const links = ['Contact', 'Get Started']
    const links = [
        // 'Contact', 
        'Get Started',
        // { text: 'Project Cost Feedback', url: '/feedback' },
        // 'Results',
        // 'FAQ', 
        // 'About'
    ]
    const [showDropdown, setShowDropdown] = useState(false)
    // const colorTheme = () => [
    //     'about',
    //     'privacy',
    //     'terms',
    //     'do-not-sell-my-info'
    // ].findIndex(it => pathname.includes(it)) > -1 ? '#386aff' : '#fff'
    const colorTheme = () => '#fff'
    useEffect(() => {
        if (gridContainerRef.current) {
            fixSvgElementCoordinates('.hamburgerMenu svg .upperLine', 0, 2, 5, 4)
            fixSvgElementCoordinates('.hamburgerMenu svg .lowerLine', 0, 67, 5, 67)
        }

    }, [gridContainerRef.current])

    const fillColor =
        (location.pathname !== "/" &&
            ["/about", "/terms", "/privacy", "/do-not-sell-my-info"].findIndex((el) =>
                location.pathname.includes(el)
            ) >= 0) ||
            location.pathname.includes("company")
            ? "var(--formBlue1)"
            : "var(--hamburgerMenuFill)";
    const colorDropdown = location.pathname === '/contact' ? "#fff" : undefined
    const bgdDropdown = location.pathname === '/contact' ? "var(--formBackgroundAngledTransparent)" : undefined
    console.log(pathname)
    if ((pathname === '/' || pathname.includes('/?')) && !shouldShow)
        return (<></>)
    if (location.pathname.includes('form'))
        return (
            <GridContainer height="var(--navBarHeight)"
                padding={"1em"} zIndex="3" relative boxShadow="none" background="transparent">
                <Flexbox color="#fff" width="min-content" onClick={() => navigate('/')} fontWeight={location.pathname === '/' ? 'var(--navBarFontWeightActive)' : undefined} className="closeOrRemoveTag">&#10006;&#xFE0E;</Flexbox>

            </GridContainer>
        )
    return (
        <GridContainer
            ref={gridContainerRef}
            height="unset"
            gridTemp={{ cols: '1fr 1fr 1fr' }}
            padding={location.pathname === '/' ? padding : "1em"}
            zIndex="3"
            relative >
            <Flexbox

                // fontFamily="'Crimson Text', serif"
                // fontFamily="'Amiri', serif"
                fontFamily="'Lusitana', serif"
                // fontFamily="'Playfair Display', serif"
                fontWeight={location.pathname === '/' ? '900' : undefined}
                // fontWeight={location.pathname === '/' ? 'var(--navBarFontWeightActive)' : undefined}
                onClick={() => {
                    if (location.pathname === '/') {
                        const v = document.querySelector('.bodyContainer')
                        if (v)
                            v.scrollTo(0, 0)
                    }
                    else
                        navigate('/')
                }}
                width="min-content"
                // color={colorTheme()}
                cursor="pointer"
                whiteSpace={"nowrap"}
                zIndex="6"
            >

                {/* <img src={(!showDropdown && colorTheme() === '#fff') ? devCostCalculator : devCostCalculator} height="auto" width="100px" style={{ objectFit: 'contain' }} />
                <img src={calculator} width="32.75px" style={{marginLeft: '.5em'}}/> */}
                <img src={(!showDropdown && colorTheme() === '#fff') ? devCostFull : devCostFull} height="auto" width="150px" style={{ objectFit: 'contain' }} />
                {/* <img src={(!showDropdown && colorTheme() === '#fff') ? devCostD : devCostD} height="auto" width="50px" style={{ objectFit: 'contain' }} /> */}
                {/* <img src={(!showDropdown && colorTheme() === '#fff') ? devCostCalculator2 : devCostCalculator2} height="auto" width="130px" style={{ objectFit: 'contain' }} /> */}
            </Flexbox>
            <Flexbox justifySelf="center" width="100%" maxWidth="200px" justifyContent="space-around" gap="20px" className="desktopMenu" color={location.pathname === '/contact' ? "#fff !important" : undefined}>
                {/* {
                    !hideLinks && links.filter(it => it !== 'Contact' && it !== 'Get Started').map(it => {
                        const path = it.replace(' ', '-').toLowerCase()
                        return (<Flexbox
                            key={path}
                            cursor="pointer"
                            whiteSpace="nowrap"
                            color={fillColor}
                            fontWeight={location.pathname.includes(path) ? 'var(--navBarFontWeightActive)' : undefined}
                            width="min-content"
                            onClick={() => {
                                navigate(path)
                            }}
                        >{it}</Flexbox>)
                    })
                } */}
            </Flexbox>
            <GridContainer2 align="center" justifySelf="end" gap="20px"
                // gridTemp={{ columns: '1fr 1fr' }}
                width="min-content" mobileDisplay="none" cursor="pointer">
                {
                    !hideLinks && <>
                        {/* <Flexbox
                        className="desktopMenu"
                        // gridCol="3"
                        justifySelf="end"
                        cursor="pointer"
                        padding="10px"
                        borderRadius="3px"
                        fontWeight="700"
                        color="#386aff"
                        whiteSpace="nowrap"
                        fontSize="12px"
                        height="min-content"
                        alignSelf="center"
                        boxShadow="0 0 5px rgba(0,0,0,0.35)"
                        background="#ffffff"
                        // background="var(--formBackgroundAngled)"
                        onClick={() => {
                            navigate('/contact')
                        }}>Contact Us</Flexbox> */}
                        {/* <Flexbox
                            className="desktopMenu"
                            whiteSpace="nowrap"
                            justifySelf="end"
                            cursor="pointer"
                            padding="10px"
                            borderRadius="3px"
                            fontWeight="700"
                            color={'#fff'}
                            fontSize="12px"
                            height="min-content"
                            alignSelf="center"
                            boxShadow="0 0 5px rgba(0,0,0,0.35)"
                            background="var(--formBackgroundAngled)"
                            onClick={() => {
                                navigate('/form')
                            }}>
                            Settlement Calculator</Flexbox> */}
                        {/* <img src={calculator} width="45px" height="45px" onClick={() => {
                                navigate('/form')
                            }}/> */}
                    </>
                }
            </GridContainer2>

            <Flexbox column
                alignItems="center"
                className="hamburgerMenu"
                justifyContent="center"
                alignContent="end"
                justifyItems="end"
                justifySelf="end"
                transform={!showDropdown ? "translateX(25%)" : undefined}
                height={!showDropdown ? "60px" : undefined}
                width={!showDropdown ? "60px" : undefined}
                onClick={() => {
                    let bodyEl = document.querySelector("body");
                    if (!showDropdown) {
                        bodyEl.style.overflow = "hidden";
                    } else {
                        bodyEl.style.overflow = "visible";
                    }
                    setShowDropdown(!showDropdown);
                }}>

                <svg viewBox="0 0 100 80" width="20px" height="20px"
                    // onClick={() => {
                    //     let bodyEl = document.querySelector('body')
                    //     if (!showDropdown) {
                    //         bodyEl.style.overflow = 'hidden'
                    //     } else {
                    //         bodyEl.style.overflow = 'visible'
                    //     }
                    //     setShowDropdown(!showDropdown)
                    // }}
                    style={{
                        zIndex: '6'
                    }}
                >
                    <rect y="30" width="90" x="5" height="10"
                        style={{ fill: showDropdown ? "none" : fillColor }}
                    ></rect>
                    <rect className="upperLine" width="90" height="10" x="5"
                        style={{ transform: showDropdown ? "rotate(45deg)" : 'none', fill: showDropdown ? 'var(--formBlue1)' : fillColor }}></rect>
                    {/* <rect y="30" width="90" height="10" x="5"  ></rect> */}
                    {/* style={{transform: "rotate(45deg)"}} */}

                    <rect className="lowerLine" y="60" width="90" x="5" height="10"
                        style={{ transform: showDropdown ? "rotate(-45deg)" : 'none', fill: showDropdown ? 'var(--formBlue1)' : fillColor }}></rect>
                </svg>
                {showDropdown &&
                    <Flexbox position="fixed"
                        // height="calc(100% - var(--navBarHeight))"
                        height="100%"
                        // overflow="hidden"
                        width="100%" right="0"
                        top="0"
                        // top="var(--navBarHeight)"
                        zIndex="5">
                        <Dropdown
                            // dropdownRef={dropdownRef}
                            // listContainerRef={listContainerRef}
                            label='hamburger'
                            headerHeight="49px"
                            fontSize="1.3rem"
                            width="100%"
                            itemHeight="min-content"
                            // listBackground={bgdDropdown}
                            // color={colorDropdown}
                            itemPadding="20px 50px"
                            // height="calc(100vh - var(--navBarHeight))"
                            height="100%"
                            justifyContentList="end"
                            maxHeight={'100%'}
                            // dropdownListMarginTop={'calc(var(--navBarHeight)/2)'}
                            showHeader={false}
                            name={'Suggested Tags'}
                            marginBottom={'1rem'}
                            options={[
                                { value: 'Home', innerText: 'Home', onClick: () => navigate('/') },
                                ...links.map(it => {
                                    if (typeof (it) === 'object')
                                        return ({ value: it.text, innerText: it.text, onClick: () => navigate(it.url) })
                                    const path = it === 'Get Started' ? '/form' : `/${it.replace(' ', '-').toLowerCase()}`
                                    if (path === '/form' || path === '/contact') {
                                        return ({ value: it, innerText: it, onClick: () => { navigate(path, { state: { form_entry: 'Mobile Hamburger Menu' } }) } })
                                    }
                                    return ({ value: it, innerText: it, onClick: () => { navigate(path) } })
                                })]}
                            menuState={showDropdown}
                            handleStateChange={() => {
                                setShowDropdown(!showDropdown)
                                let bodyEl = document.querySelector('body')
                                bodyEl.style.overflow = 'visible'
                            }}
                            // currSelection={currSelection ? currSelection : null}
                            valueX={[]}
                            id={'suggested-tags'}
                            padding="0 0 var(--navBarHeight)"
                            setValidContinue={() => { }}
                            justifyContent="end"
                            textAlign="left"
                        // letterSpacing="0.1rem"
                        // fontSize="var(--fontSmall)"
                        // fontWeight="700"
                        // boxShadow='0 0 2px #000'
                        // background="#000"
                        ></Dropdown>
                    </Flexbox>}

            </Flexbox>

            {/* <Flexbox justifyContent="space-between" gap="20px">
                {
                    links.map(it => {
                        const path = it.replace(' ', '-').toLowerCase()
                        return (<Flexbox
                            key={path}
                            cursor="pointer"
                            whiteSpace="nowrap"
                            fontWeight={location.pathname.includes(path) ? 'var(--navBarFontWeightActive)' : undefined}
                            width="min-content"
                            onClick={() => {
                                navigate(path)
                            }}
                        >{it}</Flexbox>)
                    })
                }
            </Flexbox> */}
        </GridContainer>
    )
}

export default Navigation