import axios from "axios";

const api = {
    submitForm: async (_data) => {
        // try {
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/form/submit/createNewSoftwareLead`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res['data']
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    submitContactForm: async (_data) => {
        // try {
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/contact`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    checkValidity: async () => {
        return await axios({
            method: 'get', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/validatePhoneAndEmail`,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    },
    projectCostFeedback: async (_data) => {
        // try {
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/projectCostFeedback`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    getFAQ: async () => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/faq/`,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return res.data.faq
        } catch (error) {
            console.log(error)
        }

    },
    findSoftwareLeadSubmission: async (email) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/findSoftwareLeadSubmission`,
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    email: email
                }
            });
            return { msg: res.data.msg, status: res.status }
        } catch (error) {
            if (error.response && error.response.data)
                return { msg: error.response.data.msg, status: error.response.status }
        }

    },
    searchPlaces: async (search = '', states = []) => {
        try {

            let res = await axios({
                method: 'get', // & controllers
                url: `${process.env.REACT_APP_API_DOMAIN}/places/searchPlaces`,
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    search: search.replaceAll(' ', '+'),
                    states: Array.isArray(states) ? states.map(st => st.replaceAll(' ', '+')) : states.replaceAll(' ', '+')
                }
            });
            return res.data['result']
        } catch (error) {
            console.log(error)
        }
    },
}

export default api;