import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom'

const DescriptionAndKeywords = ({ pathname }) => {
        return (
            <Helmet prioritizeSeoTags>
                
                <title>Free AI-Powered Software Development Cost Calculator</title>
                <meta
                    name="description"
                    content="Software development cost calculator powered by advanced AI. Free app development cost estimation. Get cost to build web and mobile apps. Quick and easy."
                />
                <meta
                    name="keywords"
                    content="" />
            </Helmet>
        )

}

const DynamicMetaData = () => {
    const { pathname } = useLocation()
    return (
        <DescriptionAndKeywords pathname={pathname} />
    )
}

export default DynamicMetaData