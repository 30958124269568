import React from 'react'

import { Wrapper } from './GridItem.styles'

const GridItem = ({ children, onClick, ...props }) => {
    return (
        <Wrapper onClick={onClick}{...props}>{children}</Wrapper>
    )
}

export default GridItem