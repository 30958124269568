import React, { Suspense, lazy } from 'react'
import PageContentContainer from './PageContentContainer'
import Footer from './Footer'
import Navigation from './Navigation'
import Loader from './Loader'

import Section1 from './Home/Section1'
const Section2 = lazy(() => import('./Home/Section2'))
const Section3 = lazy(() => import('./Home/Section3'))
const Section4 = lazy(() => import('./Home/Section4'))
const Section5 = lazy(() => import('./Home/Section5'))
const Section6 = lazy(() => import('./Home/Section6'))
const Section7 = lazy(() => import('./Home/Section7'))
const Section8 = lazy(() => import('./Home/Section8'))
const Section9 = lazy(() => import('./Home/Section9'))
const Section10 = lazy(() => import('./Home/Section10'))


const Home2 = () => {
    return (
        <PageContentContainer
            minHeight="100vh"
            height="100vh"
            padding="0"
            color="#fff"
            mobilePadding="0 5%"
            width="100%"
            scrollBehavior="smooth"
            background="radial-gradient(circle at 25% 25%, #094d89, #050027 50% )"
            transition="0.2s all linear"
            overflow="auto scroll"
            className="bodyContainer"
            column
        >
            <Navigation shouldShow={true} padding='0 1em' />
            <Section1 />
            <Suspense fallback={<Loader />}>
                <Section2 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section3 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section4 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section5 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section6 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section7 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section8 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section9 />
            </Suspense>
            <Suspense fallback={<Loader />}>
                <Section10 />
            </Suspense>
            <Footer shouldShow={true} />
        </PageContentContainer>
    )
}

export default Home2