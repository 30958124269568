import styled from 'styled-components'

export const List = styled.ul`
display: flex;
padding: 0;
max-width: ${({ maxWidth = '600px' }) => maxWidth};
width: ${({ listWidth = '90%' }) => listWidth};
flex-wrap: wrap;
flex-direction: ${({ flexDirection = 'column' }) => flexDirection};
gap: 10px;
margin-top: ${({ marginTop }) => marginTop};
margin-bottom: 0;

.labelText{
  font-size: ${({ fontSize }) => fontSize};
}
`;

export const Text = styled.p`
color: var(--secondary);

`;

export const Check = styled.div`
height: 15px;
width: 15px;
box-shadow: var(--defaultInputBoxShadow);
background: ${({ checked }) => checked ? 'rgba(0,0,0,0.15)' : "var(--underLineThemeBgd)"};
border: ${({ checked }) => checked ? '3px solid var(--neonBlue)' : 'var(--defaultCheckRadioBorder)'};
display: flex;
  position: relative;
  border-radius: 100%;
  height: 25px;
  width: 25px;
	/* z-index: 5; */
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
  flex-shrink: 0;
:before {
  display: block;
  /* position: absolute; */
	content: '';
  border-radius: 100%;
  height: 9px;
  width: 9px;
  /* top: 5px;
	left: 5px; */
  margin: auto;
  background: ${({ checked }) => checked ? 'var(--neonBlue)' : 'transparent'};
  box-shadow: ${({ checked }) => checked ? '0px 0px 3px 2px var(--neonBlue)' : 'undefined'};
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
  flex-shrink: 0;
}
`;

export const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    text-align: start;
    display: flex;
    align-items: center;
    p{
        font-size: 0.8rem;
        margin: 0;
        color: var(--secondary);
    }
    h3{
        margin: 0;
    }
    gap: 5px;

    /* input[type=radio]:checked ~ .check {
        border: 5px solid #0DFF92; */
/* } */
    
`;