import React, { Suspense, lazy } from 'react'
import styled from 'styled-components'
import GlassButton from '../GlassButton';
import Flexbox from '../Flexbox';
// import calc from '../images/aiCalculator.webp'
// import calculator400 from '../images/calculator-400.webp'
// import calculator400p from '../images/calculator400.png'
// import calculator800 from '../images/calculator-800.webp'
import Loader from '../Loader';
import H1 from './H1';
import H2 from './H2';
import { useNavigate } from 'react-router-dom';
import CalcImage from './CalcImage';

const Calc400 = lazy(() => import(('../../images/calculator-400.webp')))
const Calc400p = lazy(() => import(('../../images/calculator400.png')))
const Calc800 = lazy(() => import(('../../images/calculator-800.webp')))
const Calc = lazy(() => import(('../../images/aiCalculator.webp')))

const HeroContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows:  1fr;
    flex-shrink: 0;
    height: calc(100vh - 60px);
    align-items: center;
    justify-content: center;
    grid-template-areas:
        'text image'; 
    padding: 0 1em;
    @media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content;
        align-content: center;
        grid-template-areas:
            'image image'
            'text text'; 
        padding: 0;
    }
`;

const Section1 = () => {
    const navigate = useNavigate()
    return (
        <HeroContainer flexDirection="row" display="grid">
            <Flexbox column alignItems="start">
                <H1 textAlign="start">Discover your software project's true cost now.</H1>
                <H2 maxWidth="1000px" textAlign="start">We take a powerful AI and train it with development cost data no one else has, to bring you the best software development cost calculator ever built.</H2>
                <GlassButton
                    text="Get Started" onClick={() => navigate("/form")} style={{ maxWidth: '400px' }} />
            </Flexbox>
            <Suspense fallback={<Loader />}>
                <CalcImage />
            </Suspense>
        </HeroContainer>
    )
}

export default Section1