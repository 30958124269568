import React from 'react'

import { CheckboxStyle } from './Checkbox.styles'

const Checkbox = ({ checked, checkOnChange, text, ...props }) => {
    return (
        <CheckboxStyle {...props}><input type="checkbox" name="checkbox" id={text} onChange={(e) => checkOnChange(e)} checked={checked} /><span></span><label htmlFor={text} className="checkbox-text">{text}</label></CheckboxStyle>
    )
}

export default Checkbox