import styled from 'styled-components';


export const LabelStyled = styled.label`
    position: ${({ position = 'relative' }) => position};
    font-weight: ${props => props.labelWeight ? props.labelWeight : ''};
    font-size:  ${props => props.labelFontSize ? props.labelFontSize : '1em'};
    text-transform:  ${props => props.labelTextTransform ? props.labelTextTransform : 'none'};
    letter-spacing: ${props => props.labelTextTransform ? '0.1rem' : ''};
    display:inline-block;
    text-align: ${({ labelTextAlign }) => labelTextAlign};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0'};
    width: ${({ width }) => width};
    color: ${({ labelColor }) => labelColor};
`;


export const InputStyled = styled.input`
    height: 49px;
    cursor: inherit;
    margin: 2px;
    caret-color: var(--caretColor);
    padding: ${({ padding, type }) => type === 'price' ? padding || '0 30px' : padding || '0 20px'};
    width: 100%;
    color: ${({ color = 'var(--primary)' }) => color};
    background: ${({ background }) => background || "var(--inputBackground)"};
    background: rgba(0,0,0,0.05);
    box-shadow: ${({ boxShadow = "var(--defaultInputBoxShadow)" }) => boxShadow};
    border: ${({ border }) => border || 'var(--inputBorder)'};
    border-bottom: ${({ underline }) => underline};
    outline: ${({ outline }) => outline || 'var(--inputOutline)'};
    position: ${({ position = 'relative' }) => position};
    transition: ${({ transition }) => transition};
    min-width:${({ minWidth }) => minWidth};
    font-size: ${({ fontSize = '16px' }) => fontSize};
    border-radius: 0;
    max-width: ${({ maxWidth }) => maxWidth};
    box-shadow: 
    /* inset -3px -3px 6px -3px rgba(0, 11, 92, 0.95),  */
    /* inset 3px 3px 6px -3px rgba(255,255,255,0.95),  */
    inset 3px 3px 6px -3px rgba(255, 255, 255,0.5)
    ;
    border: 1px solid rgba(255,255,255,0.5);
            /* border-width: 1px 0 0 1px; */
            /* border-radius: 30px; */
            
    border: ${({ border }) => border || 'var(--inputBorder)'};
    background: ${({ background }) => background || "var(--inputBackground)"};
    box-shadow: ${({ boxShadow = "var(--defaultInputBoxShadow)" }) => boxShadow};
    ::placeholder{
        color: ${({ inputPlaceHolderColor = 'var(--inputPlaceHolderColor)' }) => inputPlaceHolderColor};
    }
    
    
    :focus{
        outline: ${({ focusOutline }) => focusOutline || 'var(--inputFocusOutline)'};
        color: ${({ focusColor }) => focusColor || 'var(--inputFocusColor)'};
        border: ${({ focusBorder }) => focusBorder || 'var(--inputFocusBorder)'};
        border-bottom: ${({ underlineFocus, underline }) => underlineFocus || underline};
    }


`;

export const Wrapper = styled.div`
z-index: ${({ zIndex }) => zIndex};
    position: ${({ position = 'relative' }) => position};
    width: ${props => props.width ? props.width : props.wrapperWidth ? props.wrapperWidth : ''};
    display: ${({ display }) => display};
    flex-direction: ${({ flexDirection }) => flexDirection};
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    grid-template-rows: ${({ gridTemp }) => gridTemp ? gridTemp['rows'] : ''};
    grid-template-columns: ${({ gridTemp }) => gridTemp ? gridTemp['columns'] : ''};
    font-family: ${({ fontFamily }) => fontFamily};
`;

export const InnerWrapper = styled.div`
z-index: ${({ zIndex }) => zIndex};
cursor: inherit;
  position: ${({ position = 'relative' }) => position};
  margin-top: ${props => props.marginTop ? props.marginTop : '0'};
  width: ${props => props.width ? props.width : '100%'};
  max-width: ${({ maxWidth }) => maxWidth};
    display: flex;
    align-items: center;
    max-width: ${({ maxWidth }) => maxWidth};
    &.disabled{
        cursor: ${({ cursorDisabled }) => cursorDisabled};
    }
`;

export const DollarSign = styled.div`
user-select: none;
position: absolute;
left: 1rem;
z-index: 1;
font-size: 16px;
margin-bottom: 0.2em;
color: ${({ color = 'var(--primary)' }) => color};
`;

export const AlertIconWrapper = styled.div`
position: absolute;
right: 5px;
`;

export const SearchIcon = styled.img`
height: 15px;
width: 15px;
cursor: pointer;
opacity: ${props => props.opacity ? props.opacity : '0.25'};
position: absolute;
right: 1rem;
user-select: none;
`;