import { createGlobalStyle } from 'styled-components';

const hexToRgbA = (hex, alpha = 1) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
  }
}


export const GlobalStyle = createGlobalStyle`
    :root{
        --maxWidth: 1280px;
        --navBarHeight: 60px;
        --defaultContainerHeight: calc(100vh - var(--navBarHeight));
        --background: #fff;
        --backgroundActiveLi: rgba(255,255,255,0.25);
        --borderRightActiveLi: 2px solid rgb(255,255,255);
        --yellow: #F2BD00;
        --white: #fff;
        --primary: #fff;
        --fontColor: #000;
        --secondary: #575555;
        --lightGrey: #f2f2f2;
        --black: #000;
        --red: #9e0000;
        --green: #03cc00;
        --formBlue1: rgb(56, 106, 255);
        --formBlueRaw: 27, 134, 246;
        --formBlue1o25: rgba(56, 106, 255,0.25);
        --formBlue2: rgb(27, 134, 246);
        --formBlue2Raw: 27, 134, 246;
        --themis: var(--formBlue1);
        --defaultBorder: 1px solid var(--white);
        --borderMediumThick: 2px solid var(--primary);
        --defaultFont: 'Albert Sans', sans-serif;
        --formColor: #ffffff;
        --defaultPagePadding: 0 50px;
        --formBackground: linear-gradient(135deg,#000, #140033 90%, #320052 );
        --formBackground90: linear-gradient(90deg,#001b85, #006185);
        --formBackgroundAngled: linear-gradient(45deg,#001b85, #006185);
        --formBackgroundAngledTransparent: linear-gradient(45deg,rgba(56, 106, 255,.95), rgba(27, 134, 246,.95));
        --footerBgd: var(--formBackground);
        --footerColor: #fff;
        --primaryBackground: var(--formBackground);
        --formSelectedTileColor: var(--primary);
        --formTileBoxShadow: 0 0 5px 3px rgba(var(--formBlueRaw),1);
        --defaultTileBorder: 1px solid rgba(0,0,0,0.1);
        --defaultTileSelectedBorder: 1px solid rgba(255,255,255,1.0);
        --defaultTileBoxShadow: 0 0 5px rgba(var(--formBlue2Raw),1.0);
        --formSelectedTileBackground: var(--formBlue1o25);
        --defaultTagBgd:var(--formBlue1o25);
        --defaultTagColor:var(--primary);
        --defaultTagBorder:1px solid var(--formBlue1);
        --geoAreaStroke: rgba(56, 106, 255,1);
        --geoAreaFillDefault: rgba(56, 106, 255,0);
        --geoAreaDetailedFillDefault: rgba(255,255,255,0.05);
        --geoAreaFillHover: rgba(56, 106, 255,0.25);
        --geoAreaFillSelected: rgba(56, 106, 255,0.25) !important;
        --greyBackground: #262626;
        --greyBackground: #262626;
        --glassBgd: rgba(255,255,255,0.1);
        --buttonFontSize: 13px;
        --dropdownLetterSpacing: '';
        --dropdownBoxShadow: none;
        --dropdownBorder: var(--defaultBorder);
        --dropdownTextTransform: none;
        --dropdownHoverBackground: rgba(255,255,255,0.3); 
        --dropdownColor: #fff;
        --homeBgd: var(--primaryBackground);
        --inputBoxShadow: none;
        --defaultInputBoxShadow: inset 0 0 4px rgba(var(--formBlue2Raw),0.5);
        --checkboxBoxShadow: inset 0 0 4px rgba(var(--formBlue2Raw),0.5);
        --inputColor: #fff;
        --inputBackground: transparent;
        --inputBorder: var(--defaultBorder);
        --linkColor: var(--formBlue1);
        --linkColorDisclaimer: var(--primary);
        --underlineFocus: 2px solid var(--formBlue1);
        --underline: 2px solid var(--black);
        --underLineThemeBgd: rgba(var(--formBlue2Raw),0.1);
        --inputBackground: var(--glassBgd);
        --inputPlaceHolderColor: rgba(255,255,255,0.25);
        --dropdownSelectedItemBackground: var(--glassBgd);
        --dropdownBackground: linear-gradient(rgba(0,0,0,0.10) 90%,rgba(0,0,0,0.25));
        --inputFocusOutline: var(--defaultBorder);
        --inputFocusColor: var(--formBlue1);
        --inputFocusBorder: 1px solid var(--neonBlue);
        --listUnderLineBgdHighlight: var(--formBlue1);
        --inputLabelFontSize: 0.7rem;
        --checkboxColor: var(--primary);
        --checkboxCheck: 1px solid var(--white);
        --asSeenOnFilter: invert(50%) sepia(100%) saturate(4840%) hue-rotate(216deg) brightness(100%) contrast(103%);
        --checkboxBoxShadow: none;
        --checkboxBorder: none;
        --buttonFallbackBgd: var(--formBlue1);
        --defaultButtonFontWeight: 700;
        --buttonfontWeightHover: 700;
        --defaultButtonLetterSpacing: 0.03em;
        --defaultButtonPadding: 20px 30px; 
        --contactFormButtonColor: var(--primaryBackground);
        --contactFormButtonBgd: var(--defaultButtonColor);
        --contactFormButtonBgdHover: var(--defaultButtonColor);
        --contactFormButtonBorder: 1px solid var(--defaultButtonColor);
        --contactFormButtonBorderHover: 1px solid var(--defaultButtonColor);
        --defaultButtonBackground: #386aff;
        --buttonDisabledBackground: #c2c2c2;
        --buttonDisabledBorder: 1px solid var(--formBlue1);
        --buttonDisabledBorder2: 1px solid var(--formBlue1);
        --buttonDisabledColor: #828282;
        --buttonDisabledColor2: var(--formBlue1);
        --buttonDisabledBgd: rgb(56, 106, 255,0.15);
        --defaultButtonColor: rgb(56, 106, 255,1);
        --defaultButtonBackgroundHover: rgb(56, 106, 255,1);;
        --defaultButtonColorHover: var(--primaryBackground);
        --defaultButtonBorderHover: 1px solid rgba(255,255,255,1);
        --defaultButtonBorder: 2px solid var(--defaultButtonBackground);
        --formSubmitButtonColor: #fff;
        --formSubmitButtonBgd: var(--defaultButtonBackground);
        --formSubmitButtonColorHover: #fff;
        --formSubmitButtonBgdHover: var(--defaultButtonBackground);
        --formBackButtonColor:  var(--defaultButtonBackground);
        --formBackButtonBgd: #fff;
        --formBackButtonColorHover: #fff;
        --formBackButtonBgdHover: var(--defaultButtonBackground);
        --formBackButtonBorder: 2px solid var(--defaultButtonBackground);
        --fontMicro: 10px;
        --navArrowFill: #e0f5ff;
        --neonBlue: #00c3e6;
        --navArrowOpacity: 1;
        --navArrowStroke: var(--formBlue1);
        --navArrowCircleFill: var(--formSelectedTileBackground);
        --defaultCheckRadioBorder: 3px solid rgba(255,255,255,0.25);
        --navBarPadding: 1em 0;
        --navBarBoxShadow: 0 0 5px #000;
        --navBarFontWeightActive: 700;
        --fontSmall: 11px;
        --headerWeight: 500;
        --fontMedium: 1.1em;
        --fontBig: 1.5em;
        --fontNormal: 1.0em;
        --bold: 700;
        --medium: 500;
        --boldSmall: 600;
        --boldSmallLetterSpacing: 0.07rem;
        /* --buttonHeight: 56px; */
        --buttonBorder: 1px solid white;
        --purpleGrey: #696980;
        --themisMobileStroke: rgba(var(--formBlueRaw), 0.25);
        --defaultInputHeight: 49px;
        --caretColor: var(--formBlue1);
        --resultsTileBackground: var(--primaryBackground);
        --hamburgerMenuFill: var(--formBlue1);
        --progressBarBackground: rgba(var(--formBlueRaw),0.25);
        --progressBar: rgba(var(--formBlueRaw),0.9);
        --skipToEndBgd: var(--formSelectedTileBackground);
        --skipToEndColor: var(--navArrowStroke);
        --skipToEndBorder: 1px solid var(--navArrowStroke);
        --tagInputColor: var(--primary);
    }
    

    * {
        box-sizing: border-box;
        font-family: var(--defaultFont);
    }
    /**Toast */
    @media only screen and (min-width: 750px), screen and (min-device-width: 750px) {
      .finalStep{
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      overflow: none;
      z-index: 100;
      background: var(--primaryBackground);
      grid-template: "back content next" min-content "back disclaimer next" min-content / minmax(80px, min-content) minmax(300px, 650px) minmax(80px, min-content);
      >div{
        max-width: 650px;
      }
    }
    }
    .isLastStepContainer{
      border: none !important;
    }
    .ToastViewport {
  --viewport-padding: 25px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}
.ToastRoot {
  background-color: rgba(var(--formBlue2Raw),0.25);
  border-radius: 6px;
  box-shadow:  0 0 4px rgba(0,0,0,0.5);
  padding: 15px;
  display: grid;
  grid-template-areas: 'title action' 'description action';
  grid-template-columns: auto max-content;
  column-gap: 15px;
  align-items: center;
  justify-content: start;
}
.ToastRoot[data-state='open'] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state='closed'] {
  animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
  animation: swipeOut 100ms ease-out;
}
.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 49px;
  gap: 5px;
  border: none;
  background-color: var(--underLineThemeBgd);
  color: var(--dropdownColor);
  box-shadow: 0 0 4px rgba(var(--formBlue2Raw),0.95);
}
.SelectTrigger:hover {
  background-color: var(--mauve3);
}
.SelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
  color: var(--violet9);
}

.SelectIcon {
  color: Var(--violet11);
}
.p-autocomplete{}
.p-autocomplete-panel{
  position: fixed;
  background-color: var(--underLineThemeBgd);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  overflow: auto;
  margin-top: 10px;
}
.p-autocomplete-items{
  list-style: none;
  padding: 0;
  overflow: auto;
}
.p-autocomplete-item{
  font-size: 13px;
  line-height: 1;
  color: var(--violet11);
  height: 40px;
  padding: 0 35px 0 25px;
}
.SelectContent {
  overflow: hidden;
  background-color: var(--underLineThemeBgd);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: var(--mauve8);
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: #010024d1;
  color: #fff;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: transparent;
  color: var(--violet11);
  cursor: default;
}
.phoneAntiSpam::before{
  content: '(646)';
}
.phoneAntiSpam::after{
  content: ' 347-9990';
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.ToastTitle {
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--slate12);
  font-size: 15px;
  text-align: left;
}

.ToastDescription {
  grid-area: description;
  margin: 0;
  color: var(--slate11);
  font-size: 13px;
  line-height: 1.3;
  text-align: left;
}

.ToastAction {
  grid-area: action;
}

.Button {
    outline: none;
    border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
}
.Button.small {
  font-size: 12px;
  padding: 0 10px;
  line-height: 25px;
  height: 25px;
}
.Button.large {
  font-size: 15px;
  padding: 0 15px;
  line-height: 35px;
  height: 35px;
}
.Button.violet {
  background-color: white;
  color: var(--violet11);
  box-shadow: 0 2px 10px var(--blackA7);
}
.Button.violet:hover {
  background-color: var(--mauve3);
}
.Button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.Button.green {
  background-color: rgba(var(--formBlue2Raw),0.1);
  color: var(--formBlue1);
  box-shadow: inset 0 0 0 1px var(--formBlue1);
}
.Button.green:hover {
  box-shadow: inset 0 0 0 1px var(--formBlue1);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--formBlue1);
}
    /**End Toast */
    .PhoneInputInput{
        height: 49px;
        color: inherit;
        font-size: 16px;
        width: 100%;
        margin: 2px;
        background: var(--underLineThemeBgd);
        outline: none;
        border: none;
        box-shadow: var(--defaultInputBoxShadow);
        /* border-bottom: 1px solid #fff; */
        border-radius: 0;
        padding-left: 20px;
        padding-right: 40px;
        &:focus{
            border: none;
            border-bottom: var(--underlineFocus);
            outline: none;
        }
        ::placeholder{
          color: var(--inputPlaceHolderColor);
        }
    }
    .PhoneInput{
        width: 100%;
    }
    /* .PhoneInputCountry{
        background: none;
        display: none;
    } */
    .PhoneInputCountrySelect{
        background-color: none;
        background: rgba(0, 23, 82,1);
        /* border: none; */
        /* outline: none; */
        color: var(--primary);
        /* display: none; */
    }
    .PhoneInputCountrySelect option{
        -webkit-appearance: menulist-button;
        height: 49px;
        &:hover{
            color: rgba(0, 23, 82,1);
            background: rgba(0, 23, 82,1);
        }
        
        /* display: none; */
    }
    #root{
        max-width: 100%;
        height: 100%;
        color-scheme: dark;
    }
    .gradientText{
        background: var(--formBackgroundAngled);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .closeOrRemoveTag{
        background: var(--formBackgroundAngled);
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .noMarginForText{
        h1, h2, h3, p{
            margin: 0;
        }
    }
    .cp{
        &::after{
            content: "Copyright ©2022-2023 Development Technologies Group, LLC. All Rights Reserved.";
        }
    }
    .hideOnMobile{
        
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            position: absolute;
            z-index: -1;
            visibility: hidden;
            width: 0;
            height: 0;
            display: none;
        }
    }
    .centerOnMobile{
        
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            text-align: center;
        }
    }
    /* .changingStep{
        .previousStep{
            display: flex !important;
            animation-name: example2;
            animation-duration: 200ms;
            position: absolute;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
        .activeStep{
            position: absolute;
            animation-name: example;
            animation-duration: 200ms;
            animation-delay: 200ms;
            opacity: 0;
            animation-timing-function: linear;
            animation-fill-mode: forwards;
        }
        @keyframes example {
            from {
                transform: translateY(600px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 1;
            }
        }
        @keyframes example2 {
            from {
                transform: translateY(0);
                opacity: 1;
            }
            to {
                transform: translateY(-600px);
                display: none;
                opacity: 0;
            }
        }
    }
    &:not(.changingStep) .previousStep{
        display: none;
        transition: 0s;
    } */
    .hideOnDesktop{
        position: absolute;
        z-index: -1;
        visibility: hidden;
        width: 0;
        height: 0;
        display: none;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            position: relative;
            z-index: undefined;
            visibility: undefined;
            /* width: 0; */
            /* height: 0; */
            /* display: none; */
        }
    }
    .hideOnDesktop2{
        
        position: relative;
            display: undefined;
            visibility: undefined;
        @media only screen and (min-width: 750px), screen and (min-device-width: 750px) {
            position: absolute;
            visibility: hidden;
            display: none;
        }
    }
    .homeHeaderContainer{
        position: relative;
        overflow: hidden;
        h1, h3, p{
            transition: transform 0.5s, opacity 1.5s;
            position: relative;
        }
        h1, h3, p{
            opacity: 1;
            transform: translateY(0);
        }
        h1:not(.visible), h3:not(.visible), p:not(.visible){
            opacity: 0;
            transform: translateY(200px);
        }
    }
    .homeHeaderContainer.overflowVisible{
        overflow: visible;
    }
    h1.homeHeader{
        color: var(--formBlue1);
    }
    .homeHeader{
        width: 100%;
        max-width: 100%;
        text-align: left;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            max-width: 100%;
            width: 100%;
            text-align: center;
            
        }
        }
    
    h3.homeHeader{

        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            font-weight: 500 !important;
            
        }
        }
    
    .homeHeaderButton{
        max-width: 250px;
        @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
            max-width: 100%;
        }
    }
    @media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
      .progress-bar-form{
        position: absolute;
        left: 0;
        border-radius: 0;
        height: 7px;
        .progress-bar-progress{
        border-radius: 0;
        height: 7px;
        }
      }
        }
    
    body{
        margin: 0;
        padding: 0;
        background: var(--background);
        color: var(--white);
        height: 100%;
    }
    @font-face {
        font-family: 'Cano';
        src: local('Cano'), url({CanoOtf}) format('opentype');
        font-weight: 300;
        font-style: normal;
    }
    .left{
        text-align: left;
    }
    html{
        margin: 0;
        font-size: 15px;
        font-family: 'Colombia';
        height: 100%;
        background: var(--background);
        /* h1{
            font-size: 3em;
            font-weight: var(--bold);
        } 
        */
        h1{
            user-select: none;
            font-size: 2em;
            font-weight: var(--bold);
        }
        h2{
            user-select: none;
            font-size: 1.5em;
            font-weight: var(--medium);
        }
        h4{
            font-weight: var(--boldSmall);
            font-size: .9em;
            margin: 0;
            
            /* font-weight: var(--bold);
font-size: 16px;
line-height: 1.2rem;
letter-spacing: 0.07rem;
text-transform: uppercase; */
        }
        p{
            /* font-weight: 300; */
            font-size: 1rem;
        }
        a{
            display: flex;
            text-decoration: none;
            color: var(--fontColor);
        }

    }
    @media screen and (max-width:320px) {
    html {
        font-size: 14px;
    }
    }
    @media screen and (min-width:768px) {
    html {
        font-size: 16px;
    }
    }
    @media only screen and (max-width: 900px), screen and (max-device-width: 900px) {
        .hideOnMobile{
                visibility: hidden;
                display: none;
            }
            .p50LRMobile{
                padding-left: 50px;
                padding-right: 50px;
            }
            .flexRowMobile{
                display: flex;
                flex-direction: row
            }   
            .wrapMobile{
            flex-wrap: wrap;
            }
            .centerTextMobile{
                text-align: center;
            }
            .noWrapOnMobile{
                flex-wrap: nowrap;
            }
            
            .noPaddingMobile{
                padding: 0;
            }
        
    }
    @media only screen and (max-width: 1080px), screen and (max-device-width: 1080px) {
        .colOnMobile{
                flex-direction: column !important;
            }
    }

    @media screen and (min-width:1024px) {
    html {
        font-size: 16px;
    }
    }

    @media screen and (min-width:1300px) {
    html {
        font-size: 18px;
    }
}
`;