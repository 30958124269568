import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// CSS
import { GlobalStyle as GlobalStyle2 } from './GlobalStyle2';
import ContactForm from './components/ContactForm'
import Success from './components/Success'
import Navigation from './components/Navigation'
import Error from './components/Error'
import Footer from './components/Footer'
// import PrivacyPolicy from './components/PrivacyPolicy';
import DNSMI from './components/DNSMI';
// import TermsAndConditions from './components/TermsAndConditions';
import ScrollToTop from "./components/scrollToTop";
import { HelmetProvider } from 'react-helmet-async'
import DynamicMetaData from './components/DynamicMetaData'
import Home2 from './components/Home2'
// import Feedback from './components/Feedback';
import { Suspense, lazy } from 'react';
import Loader from './components/Loader';

const Form = lazy(() => import('./components/Form'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));

function App() {
  return (
    <HelmetProvider>
      <div className="App" style={{ background: 'var(--primaryBackground)' }}>
        <Router>
          <DynamicMetaData />
          <ScrollToTop />
          <Navigation />
          <Routes>
            <Route path="/" exact element={< Home2 />} />
            {/* <Route path="/feedback" exact element={< Feedback />} /> */}
            {/* <Route path="/find-car-accident-attorneys" exact element={< Home />} /> */}
            {/* <Route path="/test" exact element={< Test />} /> */}
            <Route path="/contact" exact element={< ContactForm />} />
            {/* <Route path="/rocket" element={<RocketScene />} /> */}
            {/* <Route path="/test" exact element={< Test />} /> */}
            {/* <Route path="/about" exact element={< About />} /> */}
            {/* <Route path="/formNew" exact element={< FormNew />} /> */}
            {/* <Route path="/faq" exact element={<FAQ />} /> */}
            {/* <Route path="/results" exact element={<Results />} /> */}
            {/* <Route path="/results2" exact element={<Results2 />} /> */}
            {/* <Route path="/results3" exact element={<Results3 />} /> */}
            {/* <Route path="/pricing" exact element={<Pricing />} /> */}
            <Route path="/form" exact element={<Suspense fallback={<Loader />}>
              < Form />
            </Suspense>} />
            <Route path="/success" exact element={< Success />} />
            <Route path="/error" exact element={< Error />} />
            <Route path="/privacy-policy" exact element={< PrivacyPolicy />} />
            <Route path="/do-not-sell-my-info" exact element={< DNSMI />} />
            <Route path="/terms" exact element={< TermsAndConditions />} />
          </Routes>
          <Footer />
          <GlobalStyle2 />
        </Router>

      </div>
    </HelmetProvider>
  );
}

export default App;
