import React, { useEffect, useRef, useState } from 'react'

import { SearchIcon, AlertIconWrapper, Wrapper, TextAreaStyled, LabelStyled, InnerWrapper, DollarSign } from './TextArea.styles'

import CheckmarkValidate from '../CheckmarkValidate'

const Label = ({ label, name, marginBottom, ...props }) => (


    <LabelStyled
        htmlFor={name}
        {...props}
    >{label}</LabelStyled>
)


const TextArea = ({
    onKeyUp,
    onKeyDown,
    onFocus,
    rows, maxLength,
    hideLabel = false,
    showValidationCheckmark,
    showCharCount = false,
    disabled,
    activeSearch, clearAll, clearListOnSearch, onSearch,
    useUnderlineTheme = false,
    isSearch = false, focusOnMount, marginTop, textAlign, uppercaseOff,
    type, image, onChange = () => { }, placeholder, value, label, name, onKeyPress, priceOnBlur, ...props }) => {
    const underLineTheme = useUnderlineTheme ? {
        background: "var(--underLineThemeBgd)",
        border: "none",
        focusBorder: "none",
        underline: "var(--underline)",
        underlineFocus: "var(--underlineFocus)",
        focusOutline: "none",
        padding: "",
        outline: "",
    } : {}
    const initial = useRef(true);
    const inputRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [initialLength, setInitialLength] = useState(0);
    function handleCursor(event) {

        event.preventDefault();

        setCursorPosition(inputRef.current.selectionStart)
        setInitialLength(inputRef.current.value.length)
        onChange(event);
    }
    useEffect(() => {
        if (initial.current) {
            initial.current = false;
            return; // if initial render, skip useEffect
        }
        if (name === 'price') {
            let length = inputRef.current.value.length
            let caretPos = length - initialLength + cursorPosition;
            console.log(length, initialLength, cursorPosition);
            inputRef.current.setSelectionRange(caretPos, caretPos);
        }
    }, [value])

    useEffect(() => {
        if (focusOnMount && inputRef.current !== null)
            inputRef.current.focus()
    }, [name])

    if (label !== undefined && !hideLabel) {

        return (
            <Wrapper {...props}>
                <Label htmlFor={name}
                    label={label}
                    {...props}
                ></Label>
                <InnerWrapper  {...props}>
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <TextAreaStyled
                        isSearch={isSearch}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled ? true : false}
                        value={value}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onKeyUp={onKeyUp}
                        rows={rows !== undefined ? rows : 3}
                        maxLength={maxLength !== undefined ? maxLength : 1000}
                        {...underLineTheme}
                        {...props}
                    />
                    {showValidationCheckmark && <CheckmarkValidate {...props} />}
                    {
                    (showCharCount && maxLength) && <div style={{ fontSize: '10px' }}>{value.length}/{maxLength}</div>
                }
                </InnerWrapper>
                
            </Wrapper >

        )
    }
    else {
        return (
            <>
                <InnerWrapper {...props}>
                    {name === 'price' && <DollarSign>$</DollarSign>}
                    <TextAreaStyled
                        isSearch={isSearch}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled ? true : false}
                        onChange={handleCursor}
                        name={name}
                        onKeyPress={onKeyPress}
                        ref={inputRef}
                        onBlur={priceOnBlur !== undefined ? priceOnBlur : undefined}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                        rows={rows !== undefined ? rows : 3}
                        maxLength={maxLength !== undefined ? maxLength : 1000}
                        {...underLineTheme}
                        {...props}
                    />
                    {showValidationCheckmark && <CheckmarkValidate {...props} />}
                    {
                    (showCharCount && maxLength) && <div style={{ fontSize: '10px', alignSelf: 'start' }}>{value.length}/{maxLength}</div>
                }
                </InnerWrapper>
                
            </>

        )
    }



}

export default TextArea
