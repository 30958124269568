import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Flexbox from '../Flexbox'
import Footer from '../Footer'
import styled from 'styled-components'
import GridContainer from '../GridContainer'
import GridItem from '../GridItem'
import { validateEmail } from '../Form'

export const MailLink = styled.a`
all: unset;
color: inherit;
width: min-content;
cursor: pointer;
font-weight: 700;
margin-left: ${({ marginLeft }) => marginLeft};
:hover{
    text-decoration: underline;
}
`

export const OrList2 = styled.ol`
counter-reset: list;
> li {
    list-style: none;
    position: relative;
    margin: 1em;
}
> li:before {
    counter-increment: list;
    content: "(" counter(list, lower-roman) ") ";
    /* position: absolute; */
    /* left: -2.6em; */
}
`;

export const RLink = styled(NavLink)`
all: unset;
color: inherit;
width: min-content;
cursor: pointer;
font-weight: 700;
margin-left: ${({ marginLeft }) => marginLeft};
font-size: ${({ fontSize }) => fontSize};
:hover{
    text-decoration: underline;
}
`

export const ListItem = styled.li`
margin: 1em 0;
`;

const Span = styled.span`
color: ${(props) => props['black'] ? "#000" : 'var(--formBlue1)'};
cursor: pointer;
font-weight: 700;
`;

export const StyledLink = ({ children, link = '', ...props }) => {
    return (
        <MailLink target="_blank" href={typeof (children) === 'string' ? validateEmail(children) ? `mailto:${children}` : children.includes('https://') ? children : `https://${children}` : link} {...props}>{children}</MailLink>
    )
}

const PrivacyPolicy = () => {
    // const location = useLocation()
    // useEffect(() => {
    //     if (location['hash'] && location['hash'].length >0) {
    //         window.location.replace(`${location['hash']}`)
    //     }
    // }, [])
    const navigate = useNavigate()
    return (
        <Flexbox column overflow="auto" alignText="start" alignItems="start" className="privacyPolicy" margin="calc(var(--navBarHeight) * -1) 0 0" padding="var(--navBarHeight) 0" background="#000" color="#fff !important" maxWidth="100vw">
            <h1 className="left" style={{ padding: '0 50px' }}>Privacy Policy</h1>
            <h3 className="left" style={{ padding: '0 50px' }}>Last Updated: March 21, 2023</h3>
            <Flexbox column alignText="start" alignItems="start" padding="0 50px">
                <p>
                    {process.env.REACT_APP_DOMAIN} ("Website"), a website owned and operated by Development Technologies Group, LLC ("we", "us", "our"), is committed to safeguarding the privacy of its users.  This Privacy Policy, along with our Cookie Notice, explains how we collect, use, share and protect personal information processed in relation to the Site and the services that we offer ("Services") (collectively, "Platform") and other interactions (e.g., customer service inquiries, live chat, social media etc.) that you may have with us. It also tells you about your choices with respect to our collection, use and disclosure use of your personal information.
                    <br /><br />This Privacy Policy will explain what information we collect about you, the steps we take to keep this information private and secure, and what we do with this information. By using this web site and submitting information, you acknowledge that you understand the Privacy Policy and agree to be bound by the terms of this policy.
                    <br /><br />Our services are only available in the United States, and are not intended, nor available, for residents or Users residing in any location outside of the United States.<br /><br />
                    YOU SHOULD READ THIS PRIVACY POLICY CAREFULLY BEFORE SUBMITTING ANY INFORMATION. BY SUBMITTING INFORMATION YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTOOD THIS POLICY AND THAT YOU AGREED TO BE BOUND BY ITS <span onClick={() => navigate('/terms')} style={{ color: 'var(--formBlue1)', cursor: 'pointer', fontWeight: '700' }}>TERMS</span>. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS PRIVACY POLICY, PLEASE EXIT THIS PAGE WITHOUT ACCESSING OR USING OUR WEB SITE OR ANY OF OUR SERVICES.
                    <br />
                    <br />
                    We want to enable you to freely decide whether you wish to use our Services or provide us with any information which may be required or obtained therefore, we encourage you to read this Privacy Policy carefully and use it to make informed decisions.
                </p>
                <h3 className="left" >Who We Are and How to Contact Us</h3>

                Development Technologies Group is a Wyoming Limited Liability Company with the following contact information:
                <p style={{ margin: '0 0 0 50px' }}>
                    <br /><span className='phoneAntiSpam'></span><br />
                    <br />Development Technologies Group, LLC <br />
                    1309 Coffeen Ave #1200
                    <br />
                    Sheridan, WY 82801
                    <br />
                    United States
                    <br /><br />

                    <MailLink href={`mailto:${process.env.REACT_APP_PRIVACY_EMAIL}`}>{process.env.REACT_APP_PRIVACY_EMAIL}</MailLink>
                </p>
                <h3>Residents of California:</h3>
                <p>
                    If you are a resident of California, as defined by the California Consumer Privacy Act ("CCPA") this entire Privacy Notice applies to you. This Privacy Policy will inform you in detail about our information collection practices and your specific rights.
                    <br /><br />
                    You can obtain specific information about the types of personally identifiable information that companies with whom they have an established business relationship have sold or shared with third parties for direct marketing purposes during the preceding calendar year. If you would like to request a list of the third parties to which we have disclosed certain personally identifiable information, please contact us at <StyledLink>{process.env.REACT_APP_PRIVACY_EMAIL}</StyledLink>. Our data security representative will process your request.
                </p>
                <h4>Right to Know</h4> <p>You have the right to request that we disclose to you the personal information that we collect, use, disclose, and sell. To submit a verifiable consumer request to know, you may email us at the email address above with "CCPA Right to Know" in the subject line and in the body of your message. In certain circumstances, we may not be able to provide specific pieces of personal information (e.g., if such disclosure creates a substantial, articulable, and unreasonable risk to the security of such personal information or our business systems).</p>

                <h4>Right to Request Deletion</h4> <p>You have the right to request that we delete any of your personal information that we collected from you and maintained, subject to certain exceptions. In certain instances where we are not required to comply with your request for deletion, we will let you know. To submit a verifiable consumer request to delete, you may e-mail us at the email address above with "CCPA Right to Delete" in the subject line and in the body of your message.</p>

                <h4>Right to Opt-Out</h4><p>You have the right to opt-out of the sale of your personal information.  Once we receive your request, we will not sell your personal information, unless an exclusion applies.  To submit a verifiable consumer request to opt-out, you may e-mail us at the email address above with "CCPA Right to Opt-Out" in the subject line.  Please see section below titled <MailLink href="#selling-or-sharing-data-with-third-parties">Selling or Sharing Data with Third Parties</MailLink> for further details.</p>

                <h4>Right to Non-Discrimination</h4><p>Should you exercise any of your rights under the CCPA, you have the right not to receive discriminatory treatment by us.</p>
                <p>We reserve the right to verify your identity before we process any of the aforementioned requests relating to your personal information. Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information.<br /><br />
                    If you have any questions, comments, or concerns regarding this Privacy Policy, our Cookie Notice and/or our data practices, or would like to exercise your rights, do not hesitate to contact us at <MailLink href={`mailto:${process.env.REACT_APP_PRIVACY_EMAIL}`}>{process.env.REACT_APP_PRIVACY_EMAIL}</MailLink> or see our information below.</p>

                <h3>Residents of Nevada</h3>
                <p>
                    FOR RESIDENTS OF NEVADA ONLY. In accordance with SB 220, Nevada consumers may opt-out of the sale of their personal information to third parties. If you reside in Nevada and you have provided us with your personal information, you may choose to opt-out of the sale of such personal information by emailing us at <MailLink href={`mailto:${process.env.REACT_APP_PRIVACY_EMAIL}`}>{process.env.REACT_APP_PRIVACY_EMAIL}</MailLink> with “Nevada Privacy Right” in the subject line. We may request for additional information from you in order to verify your identity and/or the authenticity of your request.
                </p>


                <h3>Children</h3>
                <p>
                    Children may not use our service and minors (under the age of 18) should not submit any personal information or attempt to use the service. This website does not collect or maintain information from those known to be under the age of 13, and no part of this website is structured to attract anyone under the age of 13.  The website is solely intended for use by those age 18 and above.
                </p>
                <h3 id="personal-information-collection">What personal information we collect and why we collect it</h3>
                <GridContainer boxSizing="border-box" width="100%" cellPadding="15px"
                    // cellBorderOdd="1px solid var(--formBlue1);border-left-width:1.5px;" 
                    // cellBorderEven="1px solid var(--formBlue1);border-right-width:1.5px;" 
                    cellBorder="1px solid var(--formBlue1)"
                    gridTemp={{ columns: '1fr 1fr' }} autoRows="min-content" >
                    <GridItem background="var(--formBlue1)" color="#fff">Data We Collect</GridItem>
                    <GridItem background="var(--formBlue1)" color="#fff">How We Use This Data</GridItem>
                    <GridItem>
                        <RLink to="/contact" id="data-handling-contact-us-form">"Contact Us" Page</RLink> / Contacting Us Via Email
                        <ul>
                            <li>Name</li>
                            <li>Email Address</li>
                            <li>Phone Number</li>
                            <li>Company Name</li>
                            <li>Any Additional Information you provide via the "Contact Us" page on this site (through the "Message" section) and/or the information you provide within the subject line and/or body of an email, including the data contained within any attached files or links.</li>
                        </ul>
                    </GridItem>
                    <GridItem >We will process your contact details and contact history with us to provide you with the support you have requested or response to your inquiry.
                        <br /><br />
                        We will retain our contact history and correspondence, in the event we find it applicable (e.g., in the event of future claim, to provide you with support, or to improve our services).  We may also, with your authorization, use this information to submit a "Get Started" form on your behalf, so that you may be connected with an developer.
                    </GridItem>
                    <GridItem >In some cases, we may collect technical and aggregated data about you, and may be made available or gathered via the users' use of the website and it is transmitted from the user's, including: type of browsers; type of operation system; type of device; time and date you access the website and use the Services; users' navigation and actions in the website; language preference; country or city level location.</GridItem>
                    <GridItem >We use this data solely to provide and enhance our Services and to enable operation of the website.</GridItem>
                    <GridItem>We will collect your Internet Protocol ("IP") addresses.</GridItem>
                    <GridItem>We use the IP in order to fulfill our legitimate interests to: maintain, protect and manage the website and Services; customize and improve our Service, as well as enhance your experience while you use our Services; in order to perform research, analytic and statistic regarding traffic flow and users’ interaction with our Services; in order to audit our affiliates, calculate payments and detect fraud, as well as detect and resolve security or technical issues in connection with the Services; We also may use IPs for remarketing purposes, as detailed in the <MailLink href="#cookies-and-tracking">"Cookies"</MailLink> section below.</GridItem>
                    <GridItem>We will collect all information you voluntarily provide within our <RLink to="/form">"Get Started" form</RLink>. This includes: Your name, Company name, Email, Phone number, All comments provided in "Comments" section, how soon you want to get started, your project/developer priorities (typically in "ranked" format), whether or not you need user interfaces to be created, and project types/categories (such as web, mobile, machine learning, blockchain, etc.).  By submitting your contact info you agree an advertising developer may contact you using any form of communication, including calls, emails, auto-dial, pre-recorded messages, and text messages. You understand consent is not a condition of purchase.</GridItem>
                    <GridItem>We use this data to: Calculate your project cost estimate, and match you with a development service and connect you with them, by providing the development service with all information you have provided us within this form, including contact information. We will also store this information and utilize it for additional business purposes, such as improving our advertising, improving our developer network, improving the matching/connecting algorithms or systems that determine which development service future users connect to and/or match with, or other similar business optimization purposes. <br /><br /></GridItem>


                </GridContainer>
                <h3>How We Collect the Data</h3>
                <p>

                    We may collect the above detailed data from you, in the following manners:
                    <br /><br />
                    Automatically, including through the use cookies (as elaborated in the <MailLink href="#cookies-and-tracking">"Cookies"</MailLink> section below), and other similar tracking technologies when you interact with our website and Services; or:
                    <br /><br />
                    When you voluntarily choose to provide us with information, i.e. when you contact us via Contact form, Get Started form, email, etc. Whenever technically possible and required under applicable law, we will ask for your consent to collect your Personal Data and offer you the choice to opt-out.
                </p>
                <h3 id="cookies-and-tracking">Cookies, APIs, and Similar Tracking Technologies</h3><p>When you access the website or use the Services, we may use "cookies" (or similar tracking technologies). The use of cookies is a standard industry-wide practice. A "cookie" is a small piece of information that a website assigns and stores on your computer while you are viewing a website. Cookies are very helpful and can be used for various different purposes. These purposes include allowing you to navigate between pages efficiently, enable automatic activation of certain features, remembering your preferences and making the interaction between you and our Services quicker and easier. Cookies are also used to help customize your experience.<br /><br />You can find more information about cookies at: <StyledLink >www.allaboutcookies.org</StyledLink>.
                    <br /><br />There are several types of cookies, including:</p>
                <h4>Essential, Functionality, Operation and Security Cookies.</h4>
                <p>These cookies are essential for enabling user movement around the website, for the website to function properly, and for security purposes (i.e., used to authenticate users, prevent fraudulent use, and protect user data from unauthorized parties). This category of cookies either cannot be disabled, or if disabled, certain features of the Services may not work.</p>

                <h4>Analytic, Measurement and Performance Cookies.</h4>
                <p>These cookies are used to collect information about how users use our website, in order to improve our Services and the way we offer them, as well assess performance of the Content and campaigns. These cookies enable us, for example, to assess the number of users who have viewed specific web pages and other information such as country of origin. It enables our our websites to remember information that changes the way it behaves or looks, such as your preferred language. Further, it enables to know which pages or site sections are popular, and to use this information to improve our website or Service. We further use this information to compile reports, and use this information for website or service improvements.</p>

                <h4>Targeting, Advertising and Preference Cookies.</h4>
                <p>
                    These cookies are used to advertise across the internet and to display relevant ads tailored to users based on the parts of the website they have (for example, the cookie will indicate you have visited a certain webpage and will show you ads relating to that webpage)

                    <br /><br />The specific cookies and APIs (ours and third party’s) we currently use (each listed below may or may not be active at the specific time you use our website and/or service), purpose of use, their privacy policy and opt-out controls are set forth in the table below:<br /><br />
                </p>
                <GridContainer boxSizing="border-box" width="100%" cellPadding="15px"
                    // cellBorderOdd="1px solid var(--formBlue1);border-left-width:1.5px;" 
                    // cellBorderEven="1px solid var(--formBlue1);border-right-width:1.5px;" 
                    cellBorder="1px solid var(--formBlue1)"
                    gridTemp={{ columns: '1fr 2fr' }} autoRows="min-content" >
                    <GridItem background="var(--formBlue1)" color="#fff">Cookie and Purpose</GridItem>
                    <GridItem background="var(--formBlue1)" color="#fff">Privacy Policy and Opt-Out</GridItem>
                    <GridItem>
                        Cookie: Google - HSID, SID, SIDCC<br /><br />
                        Purpose: Security
                    </GridItem>
                    <GridItem>

                        Privacy Policy: <MailLink target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</MailLink> <br /><br />Opt-Out: <MailLink target="_blank" href="https://policies.google.com/technologies/cookies?hl=en#managing-cookies">https://policies.google.com/technologies/cookies?hl=en#managing-cookies</MailLink>
                    </GridItem>
                    <GridItem >Cookie: Google (AdWords, DoubleClick) - APISID, SAPISID, SSID, HSID, SID, PREF, IDE, NID,
                        1P_JAR
                        <br /><br />Purpose: Preference, Targeting and Advertising
                    </GridItem>
                    <GridItem>
                        Privacy Policy: <MailLink target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</MailLink> <br /><br /> Opt-Out: <MailLink target="_blank" href="https://policies.google.com/technologies/managing?hl=en">https://policies.google.com/technologies/managing?hl=en</MailLink>
                    </GridItem>
                    <GridItem >Cookie: Google Analytics - <br /><br />_ga<br /><br /> _gid <br /><br /> _gat_UA <br /><br />_gaexp <br /><br /> Purpose: Analytic, Measurement and Performance</GridItem>
                    <GridItem >Privacy Policy: <MailLink target="_blank" href="https://www.google.com/intl/en/policies/privacy">https://www.google.com/intl/en/policies/privacy</MailLink> <br /><br /> Opt-Out: <MailLink target="_blank" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</MailLink> </GridItem>
                    <GridItem>Cookie: Bing (Microsoft) - MR, MUID <br /><br /> Purpose: Analytic, Preference, Targeting and Advertising  </GridItem>
                    <GridItem> Privacy Policy: <MailLink target="_blank" href="https://privacy.microsoft.com/en-us/privacystatement">https://privacy.microsoft.com/en-us/privacystatement</MailLink> <br /><br /> Opt-Out: <MailLink target="_blank" href="https://account.microsoft.com/privacy/ad-settings">https://account.microsoft.com/privacy/ad-settings</MailLink> </GridItem>
                    <GridItem>Cookie: Facebook - fr <br /><br /> Purpose: Analytic, Measurement, Targeting and Advertising  </GridItem>
                    <GridItem>Privacy Policy: <MailLink target="_blank" href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</MailLink> <br /><br /> Opt-Out: <MailLink target="_blank" href="https://www.facebook.com/ads/settings">https://www.facebook.com/ads/settings</MailLink> </GridItem>



                </GridContainer>
                <p>Most browsers will allow you to erase cookies from your computer's hard drive, block acceptance of cookies, or receive a warning before a cookie is stored. You may remove our cookie by following the instructions of your device preferences. However, if you block or erase cookies some features of the Services may not operate properly and your online experience may be limited. Please see opt-out information under the <MailLink href="#user-rights-and-controls">"User Rights & Control"</MailLink> section below.</p>

                <h3 id="selling-or-sharing-data-with-third-parties">Selling or Sharing Data with Third Parties</h3>
                <p>
                    We may share or sell your Personal Data in the following circumstances:
                    <OrList2 type="i">
                        <li>when we engage third parties to perform services on our behalf, such services include maintenance, hosting, data storage, software development, security, analytics and data analysis, payment processing, marketing, email and text message distribution, customer service, and surveys; </li>
                        <li>
                            when you communicate with us by email, submit an online form through our sites and services, request a quote or to be connected/matched with an developer, other service, or otherwise submit a request through our sites and services, the personal information you provide may be shared with or sold to third parties to process or respond to your request, provide you with the products or services you requested, or a consultation or evaluation regarding the products or services you requested, including a third party developer, or other referral service, in connection with your online request.
                        </li>
                        <li>
                            user’s explicit consent - we may share or sell your Personal Data to third parties upon your explicit consent. Please note that once we share your information with third party, that information becomes subject to the other third party’s privacy practices;
                        </li>
                        <li>
                            policy enforcement - we may disclose or share your Personal Data, solely to the extent needed to enforce our policies (including our policies and agreements) and to establish or exercise our rights to defend against legal claims, as well as investigations of potential violations thereof, including without limitations, investigate, detect, prevent, or take action regarding illegal activities or other wrongdoing, suspected fraud, security or technical issues;
                        </li>
                        <li>
                            third party rights - we may share your information in order to prevent harm to the rights (including any legal rights), property (including intellectual property) or other or safety of our users or any applicable third party;
                        </li>
                        <li>law enforcement - we may disclose or share your Personal Data, solely to the extent needed to comply with any applicable law, regulation, legal process or governmental request (i.e., to comply with courts injunction, comply with tax authorities, etc.);</li>
                        <li>affiliate company and corporate transaction - we may disclose or share your Personal Data with our parent company, any subsidiaries, joint ventures, or other companies under common control ("Affiliated Companies") solely if and when applicable or necessary for the purposes described in this Privacy Policy, or in the event of a corporate transaction (e.g. sale of a substantial part of our business, merger, consolidation or asset sale). In the event of the above, our Affiliated Companies or acquiring company will assume the rights and obligations as described in this Policy;</li>
                        <li>identifiers - we may disclose or share online identifiers collected by us (e.g., IP), for the purpose of operating our business and providing the Services, as well as to calculate payments and detect fraud, security or technical issues in connection with the Service;</li>

                    </OrList2>

                </p>
                <p>Notwithstanding the above, we may share aggregate or Non-Personal Data with our business partners and other third parties in any of the above circumstances, as well as for any business operation and commercial use (for example, filling out a form with a potential business partner or service provider that asks questions relating to non-personal data, such as "How many monthly visitors does your website receive?").</p>

                <h3>Length of Time Data is Retained</h3>
                <p>
                    We may rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion. We retain the data we have collected for as long as needed to provide the Service, carry out our business purpose and to comply with our legal obligations, resolve disputes and enforce our agreements, in accordance and compliance with applicable laws, or until an individual request us to delete its Personal Data, as detailed below.
                </p>

                <h3 id="user-rights-and-controls">Users Right & Controls </h3>
                <p>
                    Individuals have the right to know what information we hold about them and, in some cases, to have such information communicated to them. Individuals may also ask for our confirmation as to whether or not we process their Personal Data. Subject to the limitations in applicable law, individuals may also be entitled to obtain from us the Personal Data they have provided to us in a structured, commonly-used, and machine-readable format, and may have the right to transmit such Personal Data to another party.
                </p>
                <p>
                    The principal rights under applicable data protection law in relation to Personal Data are as follows (depending on your jurisdiction): the right to access any information which is provided to us; the right to rectification; the right to erasure; the right to restrict processing; the right to object to processing; the right to data portability; the right to complain to a supervisory authority (in the event you are an EEA resident); and the right to withdraw consent (to the extent applicable). Users should be aware this website is based and hosted in the United States, and intended solely for users located in the United States.
                </p>
                <br /><br />
                <p>
                    We provide you with the ability to exercise certain choices and controls in connection with our treatment of your Personal Data, depending on your relationship with us. If you wish to exercise any or all of the above rights, please contact us at <StyledLink>{process.env.REACT_APP_PRIVACY_EMAIL}</StyledLink>
                </p>
                <br /><br />
                <p>
                    Where we are not able to provide you with the information for which you have asked, we will endeavor to explain the reasoning for this and inform you of your rights, including the right to complain to the supervisor authority (in the event you are EEA resident). We reserve the right to ask for reasonable evidence to verify your identity before we provide you with any such information in accordance with applicable law. In addition, the process of locating and deleting the data may take up to one (1) month (following the receipt of the validation proof we require) in accordance with applicable law.
                </p>
                <p>
                    Data privacy and related laws in your jurisdiction may provide you with different or additional rights related to the data we collect from you, which may also apply.
                </p>

                <h3>Opt-Out Right</h3>
                <p>
                    You may opt-out of the data collection, sharing process and advertising services related to our website, as follows:
                </p>
                <p>
                    You may set your browser to block all cookies, including cookies associated with our Services, or to indicate when a cookie is being set, by adjusting the privacy and security settings of your web browser. Please refer to the support page of the browser you are using. In this regard, the following are some links that you may find useful:
                </p>
                <ul style={{ listStyleType: 'none', marginLeft: '50px', '.li': { margin: '100px' } }}>
                    <ListItem>
                        GOOGLE CHROME - <StyledLink>https://support.google.com/chrome/answer/95647?hl=en</StyledLink>
                    </ListItem>
                    <ListItem>
                        FIREFOX - <StyledLink>https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop</StyledLink>
                    </ListItem>
                    <ListItem>
                        INTERNET EXPLORER - <StyledLink>https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d</StyledLink>
                    </ListItem>
                    <ListItem>
                        SAFARI - <StyledLink>https://support.apple.com/en-us/HT201265</StyledLink>
                    </ListItem>
                    <ListItem>
                        EDGE - <StyledLink>https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</StyledLink>
                    </ListItem>
                    <ListItem>
                        OPERA - <StyledLink>https://help.opera.com/en/latest/web-preferences</StyledLink>
                    </ListItem>
                </ul>
                <p>
                    You may opt-out directly from third party retargeting cookies or other ad-technology trackers through
                    self-regulator services like the Network Advertising Initiative’s ("NAI") website – NAI consumer opt-out page here: <StyledLink>https://optout.networkadvertising.org/?c=1</StyledLink> or
                    the Digital Advertising Alliance’s ("DAA") website – DAA opt-out page here: <StyledLink>https://youradchoices.com/</StyledLink>
                </p>
                <p>
                    You may also contact us at <StyledLink>{process.env.REACT_APP_PRIVACY_EMAIL}</StyledLink> and we will do our best to assist you.<br /><br />
                    Note that once you opt-out, part of the Services provided by us may no longer work or may have performance issues.
                </p>
                <h3>Data Security</h3>
                <p>

                    We take great care in implementing and maintaining the security of the website or Service and your Personal Data. We employ industry standard procedures and policies and implemented technical and administrative security measures to ensure the safety of our users’ Personal Data and prevent unauthorized access or use of any such information. However, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure and we cannot be responsible for the acts of those who gain unauthorized access or abuse our website or Service, and we make no warranty, express, implied or otherwise, that we will prevent such access.
                </p>
                <p>
                    We may adopt what we believe is appropriate data collection, storage and processing practices and security measures to protect against unauthorized access to such data, and according to applicable law requirements. If you feel that your privacy was treated not in accordance with our Privacy Policy, or if any person attempted to abuse our website or Service or acted in an inappropriate manner, please contact us directly at <StyledLink>{process.env.REACT_APP_PRIVACY_EMAIL}</StyledLink>.
                </p>
                <h3>Payment Processing</h3>
                <p>
                    We collect payments from Customers only and do not directly collect or store payment information. We use third-party, PCI-compliant, payment processors, which collect payment information on our behalf in order to complete transactions with our Customers. While our administrators are able to view and track actual transactions via the client portals of the payment processors, we do not have access to, or process, credit card information.
                </p>
                <h3>Children Privacy and Data</h3>
                <p>
                    The Services are not intended for individuals under the age of 18, Accordingly, we do not use the website to knowingly solicit data from or market to children as defined under applicable law (e.g. thirteen (13) regarding US individuals and sixteen (16) regarding European Economic Area ("EEA") individuals). We request that such individuals do not provide Personal Data through our Services. We reserve the right to request proof of age at any stage so that we can verify that children under the age of eighteen (18) are not using the website. If you become aware or have any reason to believe that a child has shared any information with us, please contact us at {process.env.REACT_APP_PRIVACY_EMAIL} and we will take reasonable steps to ensure that such information is deleted from our files.
                </p>
                <h3>Social Media</h3>
                <p>
                    We use social media plug-ins (e.g., Facebook, Twitter, etc.). These features may collect your IP address and/or other information as set forth in each social platform’s privacy policy, such as which page you are visiting on our Site, and may set a cookie to enable the feature to function properly. If you communicate with us or interact with us in any way on any social media platform, such as Twitter, Facebook, LinkedIn, etc., we may in some instances collect your personal information. Any information that you post on social media is governed by each social platform’s privacy notices, and any personal information that we collect via our social media accounts will be processed in accordance with this Privacy Policy.
                </p>
                <h3>Transfer of Data</h3>
                <p>
                    We are a United States business who will typically store and/or process your Personal Data in the USA. If you visit our website or use our Services from locations outside of the USA, please note that any information you provide to us through your use of our website or Service may be transferred to and processed in countries other than the country from which you accessed our website. We will take appropriate measures to ensure that your Personal Data receives an adequate level of data protection upon its transfer outside of your resident country. The USA has much less restrictive data privacy laws than other jurisdictions, particularly EU/EEA. Your data, if legally permitted, will be handled in accordance with United States regulations, and not those of your country or region, including GDPR, which is not a US law. If you are a resident of a jurisdiction where transfer of your personal data requires your consent, then your consent to this Privacy Policy includes your express consent for such transfer of your data.
                </p>
                <h3>Do Not Track Disclosure</h3><p>

                    The website or our Services does not respond to Do Not Track signals. For more information about Do Not Track signals, please see: <StyledLink>https://www.allaboutdnt.com/</StyledLink>.
                </p>
                <h3>Privacy Policy Amendments</h3>
                <p>
                    We reserve the right to periodically amend or revise this Privacy Policy from time to time, at our sole discretion, so please re-visit this page frequently. Any changes to this Privacy Policy will become effective immediately upon the display of the revised version. The most recent version of the Privacy Policy will always be posted. If we do make any changes, the updated date at the top of the Privacy Policy will be reflected in the "Last Revised" heading. Your continued use of the website or our Services, following the display of such modified Privacy Policy, constitutes your acknowledgement and consent of such amendments and your agreement to be bound by the terms of such amendments.
                </p>
                <p>
                    Note to California Residents: Notwithstanding the above, this Policy will be reviewed and updated every 12 months, as required under the CCPA.
                </p>
                <h3>Additional Questions</h3>
                <p >

                    If you have any questions about this Privacy Policy, please contact us through any of the following methods:</p>
                <GridContainer boxSizing="border-box" width="100%" cellPadding="15px"
                    cellBorder="1px solid var(--formBlue1)"
                    maxWidth="700px"
                    // margin="0 0 0 50px"
                    align="center"
                    gridTemp={{ columns: '1fr 1fr' }} autoRows="min-content" >
                    <GridItem background="var(--formBlue1)" color="#fff">Method</GridItem>
                    <GridItem background="var(--formBlue1)" color="#fff">Contact</GridItem>
                    <GridItem>Email</GridItem>
                    <GridItem><StyledLink >{process.env.REACT_APP_PRIVACY_EMAIL}</StyledLink></GridItem>
                    <GridItem height="100%" display="grid" align="center">Phone</GridItem>
                    <GridItem><p style={{ fontWeight: '700', whiteSpace: 'nowrap' }} className="phoneAntiSpam"></p></GridItem>
                    <GridItem>Contact Form On This Website</GridItem>
                    <GridItem><RLink to="/contact" target="_blank" >"Contact Us" page</RLink></GridItem>
                    <GridItem height="100%" display="grid" align="center">Mail</GridItem>
                    <GridItem><p style={{ fontWeight: '700', whiteSpace: 'nowrap' }}>
                        Development Technologies Group LLC<br />
                        1309 Coffeen Ave #1200<br />
                        Sheridan, WY 82801<br />
                        United States<br />
                    </p></GridItem>

                </GridContainer>

            </Flexbox>
        </Flexbox>
    )
}
export default PrivacyPolicy