import React, { useEffect } from 'react'
import styled from 'styled-components'
import anime from 'animejs';
const Container = styled.div`
position: ${({ position = 'absolute' }) => position};
height: 15px;
width: 15px;
/* border: 1px solid #fff;
border-radius: 50%; */
/* right: 15px; */
/* margin-bottom: 2px; */
top: ${({ top = "calc(50% - 15px / 2)" }) => top};
right: ${({ right = "3px" }) => right};
margin: ${({ margin }) => margin};
`;

const SVG = styled.svg`
height: 15px;
width: 15px;

.cls-1{
    fill:none;
    stroke:${({ color, isValid }) => color ? color : isValid ? '#00c267' : '#ff0a0a'};
    /* stroke:var(--primary); */
    stroke-miterlimit:10;stroke-width:2px;
}
.circleContainer{
    stroke: ${({ hideContainerStroke }) => hideContainerStroke ? "none !important" : undefined};
    stroke-width: ${({ hideContainerStroke }) => hideContainerStroke ? "0" : undefined};
}
.checkmark{
    visibility: hidden;
}
.badValueMark{
    visibility: ${({ isValid }) => isValid ? 'hidden' : 'visible'};
}
`;

const CheckmarkValidate = ({ shouldShow, isValid = false, top, right, position, hideContainerStroke, ...props }) => {

    useEffect(() => {
        if (isValid)
            anime({
                targets: '.checkmark',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 500,
                delay: function (el, i) { return i * 25 },
                direction: 'alternate',
                loop: false
            });
    }, [isValid])
    return (
        <Container top={top} right={right} position={position}  {...props}>
            <SVG id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.48 35.48" isValid={isValid} hideContainerStroke={hideContainerStroke}>
                <g id="Layer_2-2">
                    <circle className="cls-1 circleContainer" cx="17.74" cy="17.74" r="16.74" />
                    <path className="cls-1 badValueMark" x1="10.91" y1="24.58" x2="24.58" y2="10.91" d="M10.91 24.58 L24.58 10.91" />
                    <path className="cls-1 badValueMark" x1="10.91" y1="10.91" x2="24.58" y2="24.58" d="M10.91 10.91 L24.58 24.58" />
                    <path className="cls-1 checkmark" stroke-miterlimit="10" d="M8.86 17.13 15.19 23.46 26.62 12.03" style={{ visibility: isValid ? 'visible' : 'hidden' }} />
                </g>
            </SVG>
        </Container>
    )
}

export default CheckmarkValidate