import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// phone number optional, email before number
import Step from "./Step";
import NavArrow from "./NavArrow";
import Flexbox from "../Flexbox";
import ProgressBar from "./ProgressBar";
import FormGrid from "./FormGrid";
import FormContentContainer from "./FormContentContainer";
import PageContentContainer from "../PageContentContainer";
import GridContainer from "../GridContainer";
import Button from "../Button";
import { GridWrapper } from "./Form.styles";

import gameDevelopment from "../../images/game-dev.svg";
import artificialIntelligence from "../../images/artificial-intelligence.svg";
import dataAnalytics from "../../images/data-analytics.svg";
import blockchainDev from "../../images/blockchain-development.svg";
import augmentedReality from "../../images/augmented-reality.svg";
import enterprise from "../../images/enterprise.svg";
import notApplicable from "../../images/notApplicable.svg";
import notSure from "../../images/not-sure.svg";
import internetOfThings from "../../images/internet-of-things.svg";

import mobileApp from "../../images/mobile-app.svg";
import webDev from "../../images/web-dev.svg";
import customSoftware from "../../images/custom-software.svg";
import maintenance from "../../images/maintenance.svg";
import other from "../../images/other.svg";

import projectCost from "../../images/project-cost.svg";
import futureScalability from "../../images/future-scalability.svg";
import developmentTime from "../../images/development-time.svg";
import userExperienceProductDesign from "../../images/user-experience-product-design.svg";
import communication from "../../images/communication.svg";

import noDesignWorkNeeded from "../../images/no-design-work-needed.svg";
// import designWorkNeeded from '../../images/designWork2.svg'
import designWorkNeeded from "../../images/design-work-needed.svg";

import onshore from "../../images/onshore.svg";
import offshore from "../../images/offshore.svg";

import API from "../../API";
import { useUnload } from '../../hooks/useUnload'

import {
  formatPhoneNumber,
  IconSelectionOption,
  newEnum,
  toTitleCase,
} from "../../utils";
import states from "../../utils/states.js";
import GridItem from "../GridItem";

import node from "../../images/node.svg";
import python from "../../images/python.svg";
import mongodb from "../../images/mongodb2.svg";
import react from "../../images/react.svg";
import vue from "../../images/vue.svg";
import angular from "../../images/angular.svg";
import go from "../../images/go_1.svg";
import express from "../../images/express.svg";
import aws from "../../images/aws.svg";
import lambdaAWS from "../../images/lambdaAWS.svg";
import microsoftAzure from "../../images/microsoftAzure.svg";
import php from "../../images/php.svg";
import nosql from "../../images/nosql.svg";
import sql from "../../images/sql.svg";
import mysql from "../../images/mysql.svg";
import graphql from "../../images/graphql.svg";
import cassandra from "../../images/cassandra.svg";
import ios from "../../images/iOS.svg";
import android from "../../images/android.svg";
import firebase from "../../images/Firebase_Logo_Vertical_Lockup.svg";
import apache from "../../images/apache.svg";
import linux from "../../images/linux.svg";
import googleCloud from "../../images/googleCloud.svg";
import rubyOnRails from "../../images/ruby-on-rails.svg";
import django from "../../images/django-logo.svg";
import postgresql from "../../images/postgresql.svg";
import cardano from "../../images/cardano.svg";
import ethereum from "../../images/ethereum2.svg";
import solidity from "../../images/solidity.svg";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function validateName(str) {
  return /\d/.test(str);
}

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formAbandonment = useRef('')
  const formEntry = useRef('Embedded Header')
  useEffect(() => {
    if (location.state && location.state.form_entry) {
      formEntry.current = location.state.form_entry
    }
  }, [])
  const [serverResponse, setServerResponse] = useState({
    message: "",
    shouldShow: false,
  });
  let previousFormData = window.sessionStorage.getItem("formData");

  if (previousFormData) {
    previousFormData = JSON.parse(previousFormData);
  }
  const getPrevValue = (name, defaultVal = "") => {
    return previousFormData && previousFormData[name]
      ? previousFormData[name]
      : defaultVal;
  };
  const _data = {
    steps: {
      "step-intro": {
        id: "step-intro",
        name: "moreInfo",
        type: "text",
        stepWidth: "100%",
        value: "",
        // value: getPrevValue('moreInfo', "email"),
        heading: "Ready to get started?",
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        subtextInternal: true,
        subtext: (
          <p style={{ margin: "0", maxWidth: "700px" }}>
            In this short form we'll ask about your project details so we can estimate the cost.
          </p>
        ),
        labelInternal: true,
        validate: function () {
          return true;
        },
      },
      "step-9": {
        id: "step-9",
        name: "moreInfo",
        type: "text",
        stepWidth: "100%",
        value: "",
        // value: getPrevValue('moreInfo', "email"),
        heading: "Tell us what happened",
        subtext: (
          <>
            The next few questions are{" "}
            <strong style={{ textDecoration: "underline" }}>optional</strong>,
            <br />
            but may be helpful to know before your call.
            <br /> <br /> Feel free to skip them if you prefer.
          </>
        ),
        labelInternal: true,
      },
      // "step-10": {
      //   id: "step-10",
      //   name: "additionalDetails",
      //   type: "textArea",
      //   placeholder: "Additional Details",
      //   label: "Please tell us more details about your project. (Optional)",
      //   value: getPrevValue("additionalDetails"),
      //   maxlength: 500,
      //   rows: 5,
      //   stepHeight: "min-content min-content",
      //   outerContainerAlignment: "center",
      //   outerContainerProps: {
      //     height: "min-content",
      //   },
      // },
      "step-7": {
        id: "step-7",
        type: "final-step",
        name: "finalStep",
        stepWidth: "100%",
        labelInternal: true,
        mobileOuterContainerHeight: "100%",
        pageCProps: {
          mobilePadding: "0",
          padding: "0",
        },
        outerContainerProps: {
          overflow: "auto",
          justifyContent: "start",
          maxHeight: "calc(100vh - 100px)",
          mobilePadding: "0",
          padding: "0",
        },
        containerProps: {
          mobilePadding: "0 20px 0",
          border: "none",
          height: "100%",
          padding: "0 20px",
        },
        fieldOrder: ["field-4", "field-5", "field-3", "field-1", "field-2"],
        validate: function () {
          return this.fieldOrder.reduce((a, b) => {
            let field = this.fields[b]
            if (typeof (field.validate) === 'function') {
              let fieldVal = field.validate()
              if (!fieldVal)
                a = false

            }
            return a
          }, true)
        },
        // validate: function () {
        //   let isValid = true;
        //   let keys = this.fieldOrder;
        //   for (let index = 0; index < keys.length; index++) {
        //     const element = this.fields[keys[index]];
        //     if (typeof element["validate"] === "function") {
        //       let v = element.validate();
        //       if (!v["value"]) {
        //         isValid = false;
        //         setServerResponse({
        //           message: v["message"] ? v["message"] : "",
        //           shouldShow: false,
        //         });
        //         break;
        //       }
        //     }
        //   }
        //   if (isValid) setServerResponse({ message: "", shouldShow: false });
        //   return isValid;
        // },
        fields: {
          "field-1": {
            id: "field-1",
            name: "phoneNumber",
            placeholder: "Phone / Zoom / Skype",//{ phoneNumber: "Phone / Zoom / Skype", extension: "25" },
            label: "Phone",
            stepWidth: "100%",
            labelInternal: true,
            get isValid() {
              return this.validate(this.value)
            },
            shouldShow: false,
            invalidField: "Please provide a phone number.",
            type: "tel",
            value: getPrevValue("phoneNumber", ""),
            validate: function (_v) {
              if (this.value === undefined && _v === undefined)
                return false
              return isPossiblePhoneNumber(_v || this.value)
              // return true
              if (this.value["phoneNumber"].trim() === "") {
                return { value: false, message: "Phone number required" };
                // return ({ value: false, message: 'Phone number required' })
              }
              if (
                this.value["phoneNumber"].length > 0 &&
                !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
                  this.value["phoneNumber"]
                )
              )
                return { value: false, message: "Invalid phone number" };
              if (/^[0-9]+$/.test(this.value["extension"]))
                return { value: true, message: "" };
              return { value: true, message: "" };
            },
            onChange: function (v) {
              const _val = v;
              setServerResponse({ message: "", shouldShow: false });

              let isValid = this.validate(_val)
              console.log("isValid", isValid, _val)
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-7"]: {
                    ...prev.steps["step-7"],
                    fields: {
                      ...prev.steps["step-7"].fields,
                      [this.id]: {
                        ...prev.steps["step-7"].fields[this.id],
                        value: _val,
                        isValid
                      },
                    },
                  },
                },
              }));
            },
            // onKeyDown: function (event) {
            //   // console.log("Event", event);
            // },
            // onChange: function (value, type = "extension") {
            //   setServerResponse({ message: "", shouldShow: false });
            //   setState(prev => ({
            //     ...prev,
            //     steps: {
            //       ...prev.steps,
            //       ["step-7"]: {
            //         ...prev.steps["step-7"],
            //         fields: {
            //           ...prev.steps["step-7"].fields,
            //           [this.id]: {
            //             ...prev.steps["step-7"].fields[this.id],
            //             value,
            //           },
            //         },
            //       },
            //     },
            //   }));
            // },
          },
          "field-2": {
            id: "field-2",
            name: "additionalDetails",
            type: "textArea",
            placeholder: "Please write a brief overview of your software project.",
            label: "Briefly Describe Your Project",
            value: getPrevValue("additionalDetails", ""),
            subtext: "A description of your project will help increase the accuracy of the quote you receive.",
            invalidField: "Please provide a brief description of your project",
            maxlength: 1000,
            get isValid() {
                return this.validate(this.value)
            },
            shouldShow: false,
            isOptional: false,
            maxlength: 1000,
            validate: function (_v) {
              return ((_v || this.value).trim().length > 0)
            },
            onChange: function (event) {
              const _val = event.target.value;
              console.log("_val", _val)
              setServerResponse({ message: "", shouldShow: false });

              let isValid = this.validate(_val)
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-7"]: {
                    ...prev.steps["step-7"],
                    fields: {
                      ...prev.steps["step-7"].fields,
                      [this.id]: {
                        ...prev.steps["step-7"].fields[this.id],
                        value: _val,
                        isValid
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-3": {
            id: "field-3",
            name: "email",
            placeholder: "Email",
            label: "Email",
            value: getPrevValue("email"),
            get isValid() {
                return this.validate(this.value)
            },
            shouldShow: false,
            validate: function () {
              return validateEmail(this.value)
            },
            onChange: function (event) {
              const _val = event.target.value;
              setServerResponse({ message: "", shouldShow: false });
              let isValid = this.validate(_val)
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-7"]: {
                    ...prev.steps["step-7"],
                    fields: {
                      ...prev.steps["step-7"].fields,
                      [this.id]: {
                        ...prev.steps["step-7"].fields[this.id],
                        value: _val,
                        isValid
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-4": {
            id: "field-4",
            name: "name",
            placeholder: "Name",
            label: "Name",
            value: getPrevValue("name"),
            get isValid() {
                return this.validate(this.value)
            },
            shouldShow: false,
            validate: function () {
              return (!/\d/.test(this.value) && this.value.trim() !== "")
            },
            onChange: function (event) {
              const _val = event.target.value;
              setServerResponse({ message: "", shouldShow: false });
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-7"]: {
                    ...prev.steps["step-7"],
                    fields: {
                      ...prev.steps["step-7"].fields,
                      [this.id]: {
                        ...prev.steps["step-7"].fields[this.id],
                        value: _val,
                        isValid: !/\d/.test(_val) && _val.trim() !== ""
                      },
                    },
                  },
                },
              }));
            },
          },
          "field-5": {
            id: "field-5",
            name: "company",
            placeholder: "Company",
            label: "Company",
            value: getPrevValue("company"),
            isValid: true,
            shouldShow: false,
            validate: function () {
              return true
            },
            onChange: function (event) {
              const _val = event.target.value;
              setServerResponse({ message: "", shouldShow: false });
              setState((prev) => ({
                ...prev,
                steps: {
                  ...prev.steps,
                  ["step-7"]: {
                    ...prev.steps["step-7"],
                    fields: {
                      ...prev.steps["step-7"].fields,
                      [this.id]: {
                        ...prev.steps["step-7"].fields[this.id],
                        value: _val,
                      },
                    },
                  },
                },
              }));
            },
          },
          // 'field-6': {
          //     id: 'field-6',
          //     name: 'jobTitle',
          //     placeholder: 'Enter your job title',
          //     label: "Title",
          //     value: getPrevValue('jobTitle'),
          //     validate: function () {
          //         return ({ value: true, message: '' })
          //     },
          //     onChange: function (event) {
          //         const _val = event.target.value
          //         setServerResponse({ message: '', shouldShow: false })
          //         setState(prev => ({
          //             ...prev,
          //             steps: {
          //                 ...prev.steps,
          //                 ['step-7']: {
          //                     ...prev.steps['step-7'],
          //                     fields: {
          //                         ...prev.steps['step-7'].fields,
          //                         [this.id]: {
          //                             ...prev.steps['step-7'].fields[this.id],
          //                             value: _val
          //                         }
          //                     }
          //                 }
          //             }
          //         }))

          //     }
          // },
        },
      },
      "step-105": {
        id: "step-105",
        type: 'iconSelection',
        name: "devLocation",
        isRequired: true,
        label: <>What kind of developer are you interested in hiring?<br /><span style={{ fontWeight: "300", fontSize: '12px', lineHeight: '1em' }}>NOTE: If your budget is below $10k, choose a freelancer option or offshore development company. The remaining options will exceed your budget.  Offshore is most commonly India, but includes other countries too.</span></>,
        value: getPrevValue('devLocation', []),
        valueArrayKey: "id",
        valueIsSelectedKey: "displayName",
        isValid: false,
        validate: function (_v) {
          return (_v || this.value).length > 0
        },
        multiSelect: true,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: false,
        labelInternal: true,
        outerContainerProps: {
          maxWidth: "700px",
          height: 'min-content'
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
        options: newEnum([
          { displayName: "Offshore freelancer $ (Success rate 20%)", id: "Offshore freelancer $ (Success rate 20%)", },
          { displayName: "US freelancer $$ (Success rate 35%)", id: "US freelancer $$ (Success rate 35%)", },
          { displayName: "Offshore development company $$ (Success rate 40%)", id: "Offshore development company $$ (Success rate 40%)", },
          { displayName: "US development company $$$ (Success rate 95%)", id: "US development company $$$ (Success rate 95%)", },
          { displayName: "Full-time employees (in office or remote) - $$$$ (Success rate 95%)", id: "Full-time employees (in office or remote) - $$$$ (Success rate 95%)", }
        ]),
      },
      "step-2": {
        id: "step-2",
        name: "platforms",
        type: "iconSelection",
        multiSelect: true,
        containerProps: { height: "min-content" },
        itemsPerRow: 4,
        itemsPerRowMobile: 1,
        tileProps: {
          maxWidth: "200px",
          maxHeight: "150px",
          fontSize: "calc(1/0.90 * 1.0rem)",
        },
        enum: newEnum([
          new IconSelectionOption("Mobile App", mobileApp),
          new IconSelectionOption("Web", webDev, "web-dev"),
          new IconSelectionOption("Other", customSoftware, "other-1"),
          new IconSelectionOption("Not Sure", notSure, "not-sure-1"),
        ]),
        value: getPrevValue("platforms", []),
        textAreaValue: "",
        showTextOnly: false,
        isOptional: true,
        storeFullOption: true,
        onChange: function (optionId, currentValue, _steps, optionFull) {
          // const dependentStep = Object.keys(_steps).find(step => _steps[step]['dependency'] === this.name)
          // if (dependentStep) {
          //     _steps[dependentStep].onDependencyUpdate(optionId)
          // }

          let newValue = (({ id, displayName }) => ({ id, displayName }))(
            optionFull
          );
          let runTimes = 1;
          const arrIncludesNotSure =
            currentValue.findIndex((el) => el["id"].includes("not-sure")) >= 0;
          if (!arrIncludesNotSure && newValue["id"].includes("not-sure"))
            newValue = [newValue];
          else {
            newValue = [...currentValue, newValue].reduce(
              (a, b, index, array) => {
                if (a.findIndex((el) => el["id"] === b["id"]) >= 0)
                  return a.filter((it) => it["id"] !== b["id"]);
                else if (!b["id"].includes("not-sure")) a.push(b);
                return a;
              },
              []
            );
          }
          runTimes += 1;
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              ["step-2"]: {
                ...prev.steps["step-2"],
                value: newValue,
              },
            },
          }));
        },
        label: "Which platforms are you interested in?",
        labelInternal: true,
      },
      "step-3": {
        id: "step-3",
        name: "subcategory",
        type: "iconSelection",
        multiSelect: true,
        stepWidth: "100%",
        mobilePadding: "0 20px 20px",
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        stepHeightMobile: "repeat(2, min-content)",
        gridTempAreasMobile: "'content content''back next'",
        gridTempRowsMobile: "minmax(275px,100%) min-content",
        enum: {
          "game-development": {
            text: "Game Development",
            displayName: "Game Development",
            id: "game-development",
            img: gameDevelopment,
            // imgProps: { height: '80px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          "artificial-intelligence": {
            text: "Artificial Intelligence",
            displayName: "Artificial Intelligence",
            id: "artificial-intelligence",
            img: artificialIntelligence,
            // imgProps: { height: '80px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          "data-analytics": {
            text: "Data Analytics",
            displayName: "Data Analytics",
            id: "data-analytics",
            img: dataAnalytics,
            // imgProps: { height: '80px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          "blockchain-dev": {
            text: "Blockchain Development", //follow-up qs: what chain?
            displayName: "Blockchain Development", //follow-up qs: what chain?
            id: "blockchain-dev",
            img: blockchainDev,
            // imgProps: { height: '80px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          "augmented-or-virtual-reality": {
            text: (
              <>
                Augmented or
                <br />
                Virtual Reality
              </>
            ),
            displayName: "Augmented or Virtual Reality",
            id: "augmented-or-virtual-reality",
            img: augmentedReality,
            // imgProps: { height: '80px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          enterprise: {
            text: "Enterprise / Government",
            displayName: "Enterprise / Government",
            id: "enterprise",
            img: enterprise,
            // imgProps: { height: '80px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          "internet-of-things": {
            text: "Internet of Things",
            displayName: "Internet of Things",
            id: "internet-of-things",
            img: internetOfThings,
            // imgProps: { height: '100px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
          "not-sure-3": {
            text: "Not Sure Yet",
            displayName: "Not Sure Yet",
            id: "not-sure-3",
            img: notSure,
            // imgProps: { height: '100px', width: '100%', mobileHeight: '40px' },
            tileProps: {
              display: "flex",
              flexDirection: "column",
              justify: "space-around",
            },
          },
        },
        imgProps: { height: "70px", width: "100%" },
        tileProps: {
          noBorder: true,
          flexBasis: `calc(${(1 / 4) * 100}% - 5px * 3 * 2)`,
          width: `calc(${(1 / 4) * 100}% - 5px * 3)`,
          maxWidth: "250px",
          flexDirection: "column",
          minWidth: "156px",
          textPosition: "top",
          boxShadow: "0 0 5px rgba(0,0,0,0.3)",
          fontSize: "calc(1/0.90 * 1.0rem)",
          margin: "7.5px",
          flexShrink: "0",
          flexGrow: "1",
          height: "100%",
          maxHeight: "150px",
          justifyContent: "center",
          display: "flex",
        },
        mobileTileProps: {
          mobileFontSize: "calc(14px * 1/0.9)",
          mobileHeight: "auto",
          mobileFlexBasis: `calc(${(1 / 2) * 100}% - 15px * 2)`,
          mobileMaxHeight: "calc((100vh - 50px)/4 - 4 * 20px)",
          minHeightMobile: "110px",
          mobileWidth: "50%",
          justify: "center",
          display: "flex",
        },
        outerContainerProps: {
          justifyContent: "center",
          justifyMobile: "start",
          overflow: "hidden",
        },
        imgProps: {
          margin: "10px 0 0",
          height: "50px",
          width: "100%",
          mobileHeight: "40px",
        },
        containerProps: {
          height: "min-content",
          mobileHeight: "100%",
          mobileFontSize: "calc(14px * 1/0.9)",
          // maxHeight: 'calc(2 * 220px + 2*10px)',
          // mobileMaxHeight: 'calc((100vh - 50px)/4 - 4 * 20px)',
          width: "100%",
          maxWidth: "1100px",
          gap: "0",
          flexWrap: "wrap",
          justifyContent: "center",
          overflow: "auto",
        },
        isFlex: true,
        value: getPrevValue("subcategory", []),
        showTextOnly: false,
        isOptional: true,
        storeFullOption: true,
        // label: "Select all that may apply to your project.",
        labelInternal: true,
        // dependency: 'platforms',
        onDependencyUpdate: function (categoryId, state = "") {
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [this.id]: {
                ...prev.steps[this.id],
                "category-enum": this.getEnum(categoryId),
              },
            },
          }));
        },
      },
      "step-4": {
        id: "step-4",
        name: "techRequirements",
        type: "iconSelection",
        multiSelect: true,
        stepWidth: "100%",
        mobilePadding: "0 20px 20px",
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        stepHeightMobile: "repeat(2, min-content)",
        gridTempAreasMobile: "'content content''back next'",
        gridTempRowsMobile: "minmax(275px,100%) min-content",
        enum: {
          "not-sure-4": {
            text: "Not Sure Yet",
            displayName: "Not Sure Yet",
            id: "not-sure-4",
            img: notSure,
          },
          ios: {
            text: "iOS",
            displayName: "iOS",
            id: "ios",
            img: ios,
          },
          android: {
            text: "Android",
            displayName: "Android",
            id: "android",
            img: android,
          },
          node: {
            text: "Node",
            displayName: "Node",
            id: "node",
            img: node,
          },
          python: {
            text: "Python",
            displayName: "Python",
            id: "python",
            img: python,
          },
          mongodb: {
            text: "MongoDB",
            displayName: "MongoDB",
            id: "mongodb",
            img: mongodb,
          },
          react: {
            text: "React",
            displayName: "React",
            id: "react",
            img: react,
          },
          vue: {
            text: "Vue",
            displayName: "Vue",
            id: "vue",
            img: vue,
          },
          angular: {
            text: "Angular",
            displayName: "Angular",
            id: "angular",
            img: angular,
          },
          go: {
            text: "Go / Golang",
            displayName: "Go / Golang",
            id: "go",
            img: go,
          },
          firebase: {
            text: "",
            displayName: "Firebase",
            id: "firebase",
            img: firebase,
            imgProps: { height: "100px" },
          },
          "google-cloud": {
            text: "Google Cloud",
            displayName: "Google Cloud",
            id: "google-cloud",
            img: googleCloud,
          },
          cardano: {
            text: "Cardano",
            displayName: "Cardano",
            id: "cardano",
            img: cardano,
          },
          ethereum: {
            text: "Ethereum",
            displayName: "Ethereum",
            id: "ethereum",
            img: ethereum,
          },
          solidity: {
            text: "Solidity",
            displayName: "Solidity",
            id: "solidity",
            img: solidity,
          },
          "ruby-on-rails": {
            text: "Ruby On Rails",
            displayName: "Ruby On Rails",
            id: "ruby-on-rails",
            img: rubyOnRails,
          },
          django: {
            text: "",
            displayName: "Django",
            id: "django",
            img: django,
          },
          postgresql: {
            text: "PostgreSQL",
            displayName: "PostgreSQL",
            id: "postgresql",
            img: postgresql,
          },
          express: {
            text: "ExpressJS",
            displayName: "ExpressJS",
            id: "express",
            img: express,
            imgProps: { width: "80px" },
          },
          apache: {
            text: "Apache",
            displayName: "Apache",
            id: "apache",
            img: apache,
          },
          linux: {
            text: "Linux",
            displayName: "Linux",
            id: "linux",
            img: linux,
          },
          aws: {
            text: "Amazon Web Services",
            displayName: "Amazon Web Services",
            id: "aws",
            img: aws,
          },
          "aws-lambda": {
            text: "AWS Lambda",
            displayName: "AWS Lambda",
            id: "aws-lambda",
            img: lambdaAWS,
          },
          "microsoft-azure": {
            text: "Azure",
            displayName: "Azure",
            id: "microsoft-azure",
            img: microsoftAzure,
          },
          php: {
            text: "",
            displayName: "php",
            id: "php",
            img: php,
          },
          nosql: {
            text: "NoSQL",
            displayName: "NoSQL",
            id: "nosql",
            img: nosql,
          },
          SQL: {
            text: "SQL",
            displayName: "SQL",
            id: "sql",
            img: sql,
          },
          mysql: {
            text: "MySQL",
            displayName: "MySQL",
            id: "mysql",
            img: mysql,
          },
          graphql: {
            text: "GraphQL",
            displayName: "GraphQL",
            id: "graphql",
            img: graphql,
          },
          cassandra: {
            text: "cassandra",
            displayName: "cassandra",
            id: "cassandra",
            img: cassandra,
          },
        },
        get sortedOptions() {
          const _enum = this.enum;
          return Object.keys(_enum).sort((a, b) => {
            return _enum[a].displayName === "Not Sure Yet"
              ? -1
              : _enum[a].displayName.localeCompare(_enum[b].displayName);
          });
        },
        imgProps: { height: "70px", width: "100%" },
        tileProps: {
          noBorder: true,
          flexBasis: `calc(${(1 / 6) * 100}% - 5px * 6 * 2)`,
          width: `calc(${(1 / 6) * 100}% - 5px * 6)`,
          maxWidth: "250px",
          flexDirection: "column",
          minWidth: "100px",
          // textPosition: 'top',
          boxShadow: "0 0 5px rgba(0,0,0,0.3)",
          fontSize: "calc(1/0.90 * 1.0rem)",
          margin: "7.5px",
          flexShrink: "0",
          flexGrow: "1",
          height: "100%",
          maxHeight: "150px",
          justifyContent: "center",
          display: "flex",
        },
        mobileTileProps: {
          mobileFontSize: "calc(14px * 1/0.9)",
          mobileHeight: "auto",
          mobileMaxHeight: "calc((100vh - 50px)/4 - 4 * 20px)",
          minHeightMobile: "110px",
          mobileFlexBasis: `calc(${(1 / 2) * 100}% - 5px * 2 * 4)`,
          mobileWidth: `calc(${(1 / 2) * 100}% - 5px * 4)`,
          flexGrowMobile: "0",
          flexShrinkMobile: "1",
          justify: "center",
          display: "flex",
        },
        outerContainerProps: {
          justifyContent: "start",
          justifyMobile: "start",
          overflow: "hidden",
        },
        imgProps: {
          margin: "10px 0 0",
          height: "50px",
          width: "100%",
          mobileHeight: "40px",
          mobileWidth: "auto",
        },
        containerProps: {
          height: "min-content",
          mobileHeight: "100%",
          mobileFontSize: "calc(14px * 1/0.9)",
          // maxHeight: 'calc(2 * 220px + 2*10px)',
          // mobileMaxHeight: 'calc((100vh )/4 - 4 * 20px)',
          width: "100%",
          maxWidth: "1100px",
          gap: "0",
          flexWrap: "wrap",
          justifyContent: "center",
          overflow: "auto",
        },
        isFlex: true,
        value: getPrevValue("techRequirements", []),
        showTextOnly: false,
        isOptional: true,
        storeFullOption: true,
        onChange: function (val, currentArray, _steps, optionFull) {
          let newValue = (({ id, displayName }) => ({ id, displayName }))(
            optionFull
          );
          const arrIncludesNotSure =
            currentArray.findIndex((el) => el["id"].includes("not-sure")) >= 0;

          if (!arrIncludesNotSure && newValue["id"].includes("not-sure"))
            newValue = [newValue];
          else {
            newValue = [...currentArray, newValue].reduce(
              (a, b, index, array) => {
                if (a.findIndex((el) => el["id"] === b["id"]) >= 0)
                  return a.filter((it) => it["id"] !== b["id"]);
                else if (!b["id"].includes("not-sure")) a.push(b);
                return a;
              },
              []
            );
          }
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              ["step-4"]: {
                ...prev.steps["step-4"],
                value: newValue,
              },
            },
          }));
        },
        hideSubtextLabel: true,
        label: (
          <>
            Do you have specific technology requirements?
            <br />
            <p style={{ fontSize: "14px" }}>
              Most people can SKIP this question.
              <br /> <br />
              However, if you need to use specific tools for your project, please select them below.
            </p>
          </>
        ),
        labelInternal: true,
      },
      "step-6": {
        id: "step-6",
        name: "priorities",
        hideSubtextLabel: true,
        label: (
          <>
            Rank your priorities.
            <br />
            <p>Which of these are important to you?   <br /> <br />(Select at least one.)</p>
          </>
        ),
        type: "iconSelection",
        validate: function () {
          return this.value.length > 0;
        },
        multiSelect: true,
        showIndex: true,
        stepWidth: "100%",
        mobilePadding: "0 20px 20px",
        gridTempAreas: "'back content next'",
        stepHeight: "100%",
        stepHeightMobile: "repeat(2, min-content)",
        gridTempAreasMobile: "'content content''back next'",
        gridTempRowsMobile: "minmax(275px,100%) min-content",
        enum: {
          "budget-friendly": {
            text: "Budget Friendly",
            id: "budget-friendly",
            img: projectCost,
            imgProps: { height: "80px", width: "100%" },
          },
          "development-time": {
            text: "Development Time",
            id: "development-time",
            img: developmentTime,
            imgProps: { height: "80px", width: "100%" },
          },
          "future-scalability": {
            text: "Future Scalability",
            id: "future-scalability",
            img: futureScalability,
            imgProps: { height: "80px", width: "100%" },
          },
          "user-experience-product-design": {
            text: "User Experience / Product Design", //follow-up qs: what chain?
            id: "user-experience-product-design",
            img: userExperienceProductDesign,
            imgProps: { height: "70px", width: "100%" },
          },
          communication: {
            text: "Communication", //follow-up qs: what chain?
            id: "communication",
            img: communication,
            imgProps: { height: "70px", width: "100%" },
          },
        },
        tileProps: {
          noBorder: true,
          flexBasis: `calc(${(1 / 3) * 100}% - 5px * 3 * 2)`,
          width: `calc(${(1 / 3) * 100}% - 5px * 3)`,
          maxWidth: "250px",
          flexDirection: "column",
          gap: "5px",
          fontSize: "calc(14px * 1/0.9)",
          margin: "15px",
          // flexGrow: '1',
          height: "100%",
          maxHeight: "150px",
          justifyContent: "center",
          display: "flex",
        },
        mobileTileProps: {
          // mobileFlexBasis: '100%',
          mobileHeight: "auto",
          mobileFlexBasis: `calc(${(1) * 100}% - 15px * 2)`,
          mobileMaxHeight: "calc((100vh - 50px)/4 - 4 * 20px)",
          minHeightMobile: "110px",
          mobileWidth: "100%",
          justify: "center",
          display: "flex",
        },
        outerContainerProps: {
          justifyContent: "center",
          justifyMobile: "start",
          overflow: "hidden",
        },
        imgProps: {
          margin: "10px 0 0",
          height: "50px",
          width: "100%",
          mobileHeight: "40px",
        },
        containerProps: {
          height: "min-content",
          mobileHeight: "100%",
          maxHeight: "calc(2 * 220px + 2*10px)",
          // mobileMaxHeight: 'calc((100vh - 50px)/4 - 4 * 20px)',
          width: "100%",
          gap: "0",
          flexWrap: "wrap",
          justifyContent: "center",
          overflow: "auto",
        },
        isFlex: true,
        value: getPrevValue("priorities", []),
        showTextOnly: false,
        isOptional: true,
        onChange: function (val) {
          console.log("val", val);
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              ["step-6"]: {
                ...prev.steps["step-6"],
                value: [...prev.steps["step-6"].value, val].reduce((a, b) => {
                  if (a.includes(b)) return a.filter((it) => it !== b);
                  else a.push(b);
                  return a;
                }, []),
              },
            },
          }));
        },
        labelInternal: true,
        // dependency: 'platforms',
        onDependencyUpdate: function (categoryId, state = "") {
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [this.id]: {
                ...prev.steps[this.id],
                "category-enum": this.getEnum(categoryId),
              },
            },
          }));
        },
      },
      "step-5": {
        id: "step-5",
        name: "designWork",
        type: "iconSelection",
        multiSelect: false,
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        enum: {
          "design-work-needed": {
            text: "Yes",
            id: "yes",
            // img: designWorkNeeded,
          },
          "no-design-work-needed": {
            text: "No, I already have mockups",
            id: "user-has-mockups",
            // img: noDesignWorkNeeded,
          },
          "not-applicable": {
            text: "Not applicable",
            id: "not-applicable",
            // img: notApplicable,
          },
          "not-sure": {
            text: "Not sure yet",
            id: "not-sure",
            // img: notSure,
          },
        },
        outerContainerProps: {
          maxWidth: "700px",
          height: 'min-content'
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
        value: getPrevValue("designWork", "N/A"),
        showTextOnly: false,
        isOptional: true,
        label: "Do you require user interface design?",
        labelInternal: true,
      },
      "step-1": {
        id: "step-1",
        name: "projectStart",
        type: "iconSelection",
        value: getPrevValue("projectStart", "N/A"),
        multiSelect: false,
        enum: {
          asap: {
            text: "ASAP",
            id: "ASAP",
          },
          "less-than-1-month": {
            text: "In the next month",
            id: "In the next month",
          },
          "1-2months": {
            text: "1 to 2 months from now",
            id: "1 to 2 months from now",
          },
          "3-6months": {
            text: "3 to 6 months from now",
            id: "In 3 to 6 months",
          },
          "not-sure": {
            text: "Not sure yet",
            id: "Not Sure",
          },
        },
        itemsPerRow: 1,
        itemsPerRowMobile: 1,
        itemMargin: "7.5px",
        showTextOnly: false,
        isOptional: true,
        label: "When are you looking to start?",
        labelInternal: true,
        outerContainerProps: {
          maxWidth: "700px",
          height: 'min-content'
        },
        tileProps: {
          maxWidth: "400px",
          maxHeight: "150px",
          height: "min-content",
          fontSize: "calc(1/0.90 * 1.0rem)",
          alignItems: "start",
          alignText: "left",
          gap: "0",
        },
      },
      "step-pre-submit": {
        id: "step-pre-submit",
        name: "stepPreSubmit",
        type: "text",
        stepWidth: "100%",
        value: getPrevValue("stepPreSubmit", "email"),
        heading: "Terms and Conditions",
        subtext: (
          <Flexbox column>
            <p
              style={{
                fontSize: "11px",
                fontWeight: "400",
                letterSpacing: "0.05em",
                lineHeight: "1.5em",
              }}
            >
              By using our service and providing your personal contact
              information, you agree that an attorney and/or law firm who
              advertises through our service may contact you about your case
              using any method of communication. This may include automated
              technology, such as pre-recorded messages, SMS texting to your
              wireless number, auto-dial, and/or email. You understand and agree
              that consent is not a condition of purchase and you have read and
              agree to the Privacy Policy and Terms and Conditions. Your
              submission of this form and any related information does not
              create an attorney-client relationship and is not privileged or
              confidential. Information sent via our site is not guaranteed to
              be secure, and could be intercepted or viewed by third parties, so
              do not include any confidential information. Any attorney or law
              firm you are connected to in any way through our service is not
              obligated to accept your case, and may choose not to accept you as
              a client.
            </p>
            <Button
              background="rgba(224, 245, 255,0.25)"
              // color="#376afe"
              color="#fff"
              colorHover="#376afe"
              border="2px solid #fff"
              text="Submit"
              letterSpacing="0.05em"
              bgdHover="#fff"
              onClick={nextStep}
              width="300px"
              maxWidth="90%"
            />
          </Flexbox>
        ),
        labelInternal: true,
      },

      submit: {
        id: "submit",
        name: "submit",
        // placeholder: 'Enter your email',
        // label: "What's your email?",
        // value: '',
        // validate: function () {
        //     return validateEmail(this.value)
        // }
      },
    },
    stepOrder: [
      "step-intro",
      "step-1",
      "step-2",
      "step-3",
      "step-4",
      "step-5",
      "step-6",
      "step-105",
      // 'step-8',
      "step-7",
      "submit",
    ],
    get initialStepOrder() {
      return this.stepOrder;
    },
    get active() {
      return getPrevValue("active", this.stepOrder[0]);
    },
    startTime: Date.now(),
    endTime: null,
  };
  const [state, setState] = useState(_data);

  // const [state, setState] = useState({ steps: {}, active: '', stepOrder: [] })
  const [disabled, setDisabled] = useState(false);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const initialRender = useRef(true);
  const handleChange =
    (name, _value = null) =>
      (event) => {
        const stepId = getStep(name);
        if (name === "geographicArea" && _value) {
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [stepId]: {
                ...prev.steps[stepId],
                value: [...prev.steps[stepId].value, _value],
              },
            },
          }));
        } else if (name !== "phoneNumber" && event.target) {
          let value = event.target.value;
          setState((prev) => ({
            ...prev,
            steps: {
              ...prev.steps,
              [stepId]: {
                ...prev.steps[stepId],
                value,
              },
            },
          }));
        }
      };

  const { steps, active, stepOrder, initialStepOrder } = state;
  const formLength = Object.keys(stepOrder).length;

  const formatData = (flatten = true) => {
    const data = {};
    data.startTime = state["startTime"];
    data.endTime = Date.now();
    stepOrder.forEach((stepId) => {
      if (stepId === "submit") return;

      const step = steps[stepId];
      if (!flatten) {
        data[step.name] = step.value;
        if (step.name === "finalStep") {
          Object.keys(step["fields"]).forEach((fieldKey) => {
            const field = step.fields[fieldKey];
            data[field.name] = field.value;
          });
        }
        return;
      }
      if (step.name === "geographicArea") data["ste_name"] = step.value;
      if (step.name === "geographicAreaDetailed") {
        data["city"] = step.value.value;
        // data["ste_name"] = step.value.state
        return;
      }
      if (step.name === "testType") {
        data[step.name] = step.value.value;
        // data["ste_name"] = step.value.state
        return;
      }

      if (step.name === "courtDate") {
        data[step.name] = new Date(
          ...step.value.split("-").map((it, index) => {
            let val = parseInt(it);
            if (index === 1) val -= 1;
            return parseInt(it);
          })
        );

        return;
      }
      if (step.name === "finalStep") {
        Object.keys(step["fields"]).forEach((fieldKey) => {
          const field = step.fields[fieldKey];
          data[field.name] = field.value;
        });
      }
      if (Array.isArray(step.value))
        data[step.name] = step.value.map((it) => handleStepValues(it, step));
      else if (typeof step.value === "string")
        data[step.name] = handleStepValues(step.value, step);
      else data[step.name] = step.value;
    });
    return data;
  };

  const handleStepValues = (it, step) => {
    console.log("it: ", it)
    console.log("step: ", step)
    if (it["id"]) {
      if (/-\d/.test(it["id"])) {
        let displayName =
          step.enum[it.id]["text"] || step.enum[it.id]["displayName"];
        return {
          ...it,
          id: it["id"].split(/-\d/)[0],
          displayName: toTitleCase(displayName.replace(/-/g, " ")),
        };
      } else {
        return it;
      }
    } else {
      return toTitleCase(it.split(/-\d/)[0].replace(/-/g, " "));
    }
  };

  const submitForm = async () => {
    try {
      const data = formatData();
      const resData = await API.submitForm(data);
      if (window.sessionStorage.getItem("formData"))
        sessionStorage.removeItem("formData");
      if (resData) {
        // const { googleCount } = resData
        if (window.dataLayer && Array.isArray(window.dataLayer)) {
          let ev = {
            'event': 'form_submission',
            // 'form_id': 'PRIMARY FORM',
            // 'form_entry': formEntry.current
          }
          // if (googleCount)
          // ev['isCAC'] = true
          window.dataLayer.push(ev)
        }
      }
      navigate("/success");
    } catch (error) {
      console.log("an error occurred", error);
      if (error.response["data"]) {
        const {
          response: {
            status,
            data: { msg },
          },
        } = error;
        sessionStorage.setItem(
          "formData",
          JSON.stringify({
            ...formatData(false),
            active: stepOrder[stepOrder.length - 2],
          })
        );
        navigate("/error", {
          state: { error: { status, msg }, ref: "form-submission" },
        });
      } else {
        sessionStorage.setItem(
          "formData",
          JSON.stringify({
            ...formatData(false),
            active: stepOrder[stepOrder.length - 2],
          })
        );
        navigate("/error", {
          state: {
            error: { status: error.response.status, msg: "An error occurred" },
            ref: "form-submission",
          },
        });
      }
    }
  };

  function hasPhoneNumber() {
    const phoneNumberStep = getStep("phoneNumber");
    if (!phoneNumberStep) return false;
    if (steps[phoneNumberStep].value === "") return false;
    return true;
  }
  useUnload((e) => {

    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      if (window.dataLayer.find(it => it['event'] === 'form_submission') === undefined) {
        window.dataLayer.push({
          'event': 'form_abandonment',
          'eventCategory': 'Form Abandonment',
          'eventAction': formAbandonment.current
        })
      }
    }
  })
  const getStep = (fieldName) => {
    const stepId = Object.keys(steps).find((id) => {
      return steps[id].name === fieldName;
    });
    return stepId;
  };

  const jumpStepOnLoad = (_steps, stepOrder = []) => {
    if (location.state && location.state.jumpToStepName) {
      const {
        state: { jumpToStepName, form_entry },
      } = location;
      let stepId = getStep(jumpToStepName, _steps);
      window.history.replaceState({}, document.title);
      if (form_entry) {
        formEntry.current = form_entry
      }
      if (stepId) return stepId;
      else return getPrevValue("active", stepOrder[0]);
    } else {
      return getPrevValue("active", stepOrder[0]);
    }
  };

  const getSelectedStates = () => {
    const stepId = getStep("geographicArea");
    if (stepId) {
      let _val = steps[stepId].value;
      return steps[stepId].value;
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    validateActiveStep();
    sessionStorage.setItem(
      "formData",
      JSON.stringify({ ...formatData(false), active })
    );
  }, [state]);
  useEffect(() => {
    if (Array.isArray(window.dataLayer)) {
      if (!formAbandonment.current.includes(steps[active].name)) {
        if (formAbandonment.current === '') {
          formAbandonment.current = steps[active].name
        }
        else
          formAbandonment.current = formAbandonment.current + ` > ${steps[active].name}`
        window.dataLayer.push({
          'event': 'form_engagement',
          'eventCategory': 'Form Engagement',
          'eventAction': formAbandonment.current
        });
      }
    }
  }, [active])
  const validateActiveStep = () => {
    if (steps[active]) {
      const valid = steps[active].validate
        ? steps[active].validate(steps[active].value)
        : true;
      if (valid) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const getNextStepKey = (_stepOrder = stepOrder) => {
    const activeIndex = _stepOrder.findIndex((stepId) => stepId === active);
    return activeIndex + 1;
  };

  async function nextStep() {
    const activeIndex = stepOrder.findIndex((stepId) => stepId === active);
    const nextStep = activeIndex + 1;
    if (steps[active].onNextStep) {
      let res = steps[active].onNextStep();
      if (res === false) return;
    } else if (disabled) return;
    if (isLastStep()) {
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
      await submitForm();
    } else if (nextStep < formLength)
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
  }
  console.log("state", state, state.steps);

  const getActiveIndex = (_steps = stepOrder) =>
    _steps.findIndex((stepId) => stepId === active);

  const backStep = () => {
    const _steps = Object.keys(steps);
    const activeIndex = stepOrder.findIndex((stepId) => stepId === active); //getActiveIndex(_steps)
    const nextStep = activeIndex - 1;
    if (nextStep >= 0)
      setState((prev) => ({ ...prev, active: stepOrder[nextStep] }));
  };

  const onKeyDown = (name) => (e) => {
    const { key } = e;
    if (key === "Enter") {
      nextStep();
    } else {
      const stepId = getStep(name);
      let value = key;
      console.log("name is", name);
      if (name === "phoneNumber") {
        // const fields = steps[active]
        // const matchingField = Object.keys(fields).find(key => {
        //     const field = fields[key]
        //     return field.name === name
        // })
        // console.log("matching field is: ", matchingField)

        // const stepId = getStep(name)

        // const indexOfDependent = stepOrder.findIndex(step => steps[step]['dependency'] === name)

        // if (indexOfDependent >= 0) {

        //     const _stepId = stepOrder[indexOfDependent]
        //     const newValue = steps[_stepId].onDependencyUpdate(_val)
        //     let newStepOrder = stepOrder
        //     newStepOrder[indexOfDependent] = newValue

        //     setState(prev => ({
        //         ...prev,
        //         steps: {
        //             ...prev.steps,
        //             [stepId]: {
        //                 ...prev.steps[stepId],
        //                 value: { ...prev.steps[stepId].value, phoneNumber: _val }
        //             }
        //         },
        //         stepOrder: newStepOrder
        //     }))
        // }
        // else {
        const { fields } = steps[active];
        const matchingField = Object.keys(fields).find((key) => {
          const field = fields[key];
          console.log("field");
          return field.name === name;
        });

        if (matchingField) {
          let _val = steps[active].fields[matchingField].value["phoneNumber"];
          console.log("current value is", _val);
          if (key === "Backspace" && isKeyReleased) {
            const _noDash = _val.replace(/\D/g, "");
            _val = _noDash.slice(0, _noDash.length - 1);
            console.log("_noDash", _noDash, _val);
            _val = formatPhoneNumber(_val);
            // if (_noDash.length % 3) {
            //     _val = _val.slice(0, _val.length - 2)
            // } else {
            //     _val = _val.slice(0, _val.length - 1)
            // }
          } else {
            _val = _val + key.replace(/\D/g, "");
            const _noDash = _val.replace(/\D/g, "");
            _val = formatPhoneNumber(_noDash);
            // if (_noDash.length === 0)
            //     _val = ''
            // if (_noDash.length % 3 === 0 && _noDash.length < 7)
            //     _val += '-'
          }
          console.log("value is", _val);
          if (name === "phoneNumber")
            setState((prev) => ({
              ...prev,
              steps: {
                ...prev.steps,
                [active]: {
                  ...prev.steps[active],
                  fields: {
                    ...prev.steps[active].fields,
                    [matchingField]: {
                      ...prev.steps[active].fields[matchingField],
                      value: {
                        ...prev.steps[active].fields[matchingField].value,
                        phoneNumber: _val,
                      },
                    },
                  },
                },
              },
            }));
        }

        // }
      }
      // setIsKeyReleased(false);
    }
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const isLastStep = () => getActiveIndex() + 1 === formLength - 1;
  const isSubmitStep = () => getActiveIndex() + 1 === formLength;
  const filterDuplicatesOut = (arr, val) => {
    console.log("arr:::", arr, val);
    if (typeof val === "string") {
      return arr.reduce((a, b) => {
        if (a.includes(b)) return a.filter((it) => it !== b);
        else if (!b.includes("not-sure")) a.push(b);
        return a;
      }, []);
    } else {
      return arr.reduce((a, b, index, array) => {
        if (a.findIndex((el) => el["id"] === b["id"]) >= 0)
          return a.filter((it) => it["id"] !== b["id"]);
        else if (!b["id"].includes("not-sure")) a.push(b);
        return a;
      }, []);
    }
  };
  /*
    if storeFullOption is true for the step, option is passed as an object else it's a string
    */
  const iconSelectionOnChange = (stepId, option) => {
    const step = steps[stepId];
    if (step) {
      let storeFullOption = step.storeFullOption || false;
      let multiSelect = step.multiSelect || false;
      let currentValue = step.value;
      let newValue, arrIncludesNotSure, newValueIsNotSure;
      let additionalKeys = step.additionalKeys || [];
      let _additionalOnClick = step["onClick"] || undefined;
      let hasTextField = typeof (option) === 'object' && option.inputFieldValue !== undefined
      if (storeFullOption) {
        newValue = (({ id, displayName, ...option }) => ({
          id,
          displayName,
          ...Object.fromEntries(additionalKeys.map((it) => [it, option[it]])),
        }))(option);
      } else if (typeof option !== "string") {
        newValue = option["id"];
      }
      if (multiSelect) {
        arrIncludesNotSure =
          currentValue.findIndex((el) =>
            el["id"] ? el["id"].includes("not-sure") : el.includes("not-sure")
          ) >= 0;
        newValueIsNotSure = newValue["id"]
          ? newValue["id"].includes("not-sure")
          : newValue.includes("not-sure");
        console.log("arrIncludesNotSure: ", arrIncludesNotSure);
        if (!arrIncludesNotSure && newValueIsNotSure) newValue = [newValue];
        else {
          newValue = filterDuplicatesOut([...currentValue, newValue], newValue);
        }
      } else {
        newValue = option;
      }
      console.log("newValue is: ", newValue);
      const dependentSteps = getDependentSteps(step, steps);
      let dependentStepsObject = {};
      let newSteps = steps;
      /**
       * @type {Array}
       */
      let newStepOrder = stepOrder;
      if (dependentSteps) {
        for (let i = 0; i < dependentSteps.length; i++) {
          let dependentStep = dependentSteps[i];
          let result = steps[dependentStep].onDependencyUpdate(
            option,
            step["name"]
          );

          if (
            result["stepId"] !== undefined ||
            result["additionalValueChecks"] !== undefined
          ) {
            let updateFields = Object.fromEntries(
              Object.entries(result).filter(
                (it) =>
                  it[0] !== "stepId" &&
                  it[0] !== "additionalValueChecks" &&
                  it[0] !== "update"
              )
            );
            if (Array.isArray(result["additionalValueChecks"])) {
              let vals = {};
              for (
                let index = 0;
                index < result["additionalValueChecks"].length;
                index++
              ) {
                const stepName = result["additionalValueChecks"][index];
                const additionalCheckStepId = getStep(stepName, steps);
                if (additionalCheckStepId) {
                  let additionalCheckStep = steps[additionalCheckStepId];
                  vals[stepName] = additionalCheckStep["value"];
                }
              }
              updateFields = {
                ...updateFields,
                ...result["update"]({ ...vals }),
              };
            }
            newSteps[dependentStep] = {
              ...newSteps[dependentStep],
              ...updateFields,
            };
          }
          if (result["stepOrderChange"] !== undefined) {
            let {
              stepOrderChange: { action, stepId: depStepId },
            } = result;
            console.log("action is: ", action, "depStepId", depStepId);
            if (
              action === "add" &&
              !newStepOrder.find((el) => el === depStepId)
            ) {
              let insertionIndex = initialStepOrder.findIndex(
                (s) => s === depStepId
              );
              if (insertionIndex >= 0)
                newStepOrder.splice(insertionIndex, 0, depStepId);
            } else if (action !== "add")
              newStepOrder = newStepOrder.filter((it) => it !== depStepId);
          }
        }
      }
      let _active = active;
      if (!multiSelect && !hasTextField) {
        _active = newStepOrder[getNextStepKey(newStepOrder)];
      }
      setState(function (prev) {
        return {
          ...prev,
          stepOrder: newStepOrder,
          active: _active,
          steps: {
            ...prev.steps,
            ...newSteps,
            [stepId]: {
              ...prev.steps[stepId],
              value: newValue,
            },
          },
        };
      });
    }
  };

  function getDependentSteps(activeStep = steps["active"], _steps = steps) {
    return Object.keys(_steps).filter((_st) => {
      let _dep = _steps[_st]["dependency"];
      if (!_dep) return false;
      if (typeof _dep === "string")
        return _steps[_st]["dependency"] === activeStep["name"];
      if (Array.isArray(_dep))
        return _steps[_st]["dependency"].filter(
          (val) => val === activeStep["name"]
        );
    });
  }
  return (
    <PageContentContainer
      marginTop="0"
      position="absolute"
      top="0"
      color="#fff"
      height="100vh"
      // background="radial-gradient(circle farthest-corner at top right,var(--formBlue1),var(--formBlue2) 50%, transparent 50%)"
      bgdSize="200% 200%"
      background="radial-gradient(circle at 25% 25%, #094d89, #050027 50% )"
      justifyContent="start"
      alignItems="start"
      flexDirection="row"
      padding="0 0 50px"
      {...steps[active]["pageCProps"]}
    >
      <ProgressBar
        active={active}
        formLength={formLength - 1}
        getActiveIndex={getActiveIndex}
        isLastStep={isLastStep()}
      />
      <FormGrid stepType={steps[active].type}>
        {!isSubmitStep() && (
          <GridItem
            gridArea="back"
            alignSelf="center"
            // className={`formNavigation ${isLastStep() ? "hideOnDesktop" : ""}`}
            className="formNavigation"
            displayMobile={isLastStep() ? "none" : undefined}
          >
            <NavArrow
              isLastStep={isLastStep()}
              direction="back"
              disabled={active === stepOrder[0]}
              onClick={backStep}
            />
          </GridItem>
        )}
        {steps[active] &&
          !steps[active]["labelInternal"] &&
          steps[active].label && (
            <Flexbox
              // gridCol="2" gridRow="1"
              gridArea="label"
              alignSelf="end"
            >
              <h3>
                {steps[active] &&
                  !steps[active]["labelInternal"] &&
                  steps[active].label}
              </h3>
              {steps[active].subtextLabel && (
                <h4 style={{ margin: "inherit" }}>
                  {steps[active].subtextLabel}
                </h4>
              )}
            </Flexbox>
          )}
        <FormContentContainer stepType={steps[active].type}>
          {steps[active] && (
            <Step
              {...state}
              serverResponse={serverResponse}
              setServerResponse={setServerResponse}
              backStep={backStep}
              handleChange={handleChange}
              step={steps[active]}
              nextStep={nextStep}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              iconSelectionOnChange={iconSelectionOnChange}
              selectedStates={getSelectedStates()}
            />
          )}
        </FormContentContainer>
        {!isSubmitStep() && (
          <GridItem
            gridArea="next"
            alignSelf="center"
            justifySelf="end"
            className="formNavigation"
            width={isLastStep() ? "79px" : undefined}
            displayMobile={isLastStep() ? "none" : undefined}
          >
            {!isLastStep() && (
              <NavArrow
                disabled={disabled}
                onClick={nextStep}
                direction={isLastStep() ? "submit" : "next"}
              />
            )}
          </GridItem>
        )}
      </FormGrid>
    </PageContentContainer>
  );
};

export default Form;
