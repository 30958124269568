import React from 'react'
import Airplane from '../Airplane'
import Flexbox from '../Flexbox'

const Success = () => {
    return (
        <Flexbox column color="var(--formColor)" height="100vh" alignItems="center" justifyContent="center" background="var(--formBackground)" margin="calc(var(--navBarHeight) * -1) 0 0">
            <Airplane />
            <h1 style={{ fontSize: '2.6rem', marginTop: '0' }}>Success!</h1>
            <p style={{ maxWidth: '62%' }}>Our AI is now calculating your project cost, which will be provided shortly. <br></br><br></br>

    </p>

        </Flexbox>
    )
}

export default Success