import React from 'react'
import styled from 'styled-components'

const H2S = styled.h2`
font-size: 1.5rem;
font-weight: 100;
max-width: ${({ maxWidth = "600px" }) => maxWidth};
text-align: ${({ textAlign }) => textAlign};
`;

const H2 = ({ children, ...props }) => <H2S {...props}>{children}</H2S>

export default H2