import React from "react";

import InputField from "../../InputField";
import TextArea from "../../TextArea";
import Flexbox from "../../Flexbox";
import RadioButtons from "../../RadioButtons";
import Checkbox from "../../Checkbox";
import IconSelection from "../../IconSelection";
import TextStep from "../../TextStep";
import Dropdown from "../../Dropdown";
import FinalStep from "../FinalStep";

const Step = ({
  iconSelectionOnChange,
  serverResponse,
  setServerResponse,
  backStep,
  nextStep,
  step,
  handleChange,
  onKeyDown,
  onKeyUp,
  stepOrder,
  steps,
  selectedStates,
}) => {
  console.log("serverResponse", serverResponse);
  const { name, value, placeholder, type } = step;
  if (step.name === "submit")
    return (
      <Flexbox column>
        <h1>Submitting...</h1>
      </Flexbox>
    );
  if (step.name === "geographicArea")
    return (<></>
    );
  if (step.name === "geographicAreaDetailed")
    return (
      <></>
    );
  if (step.name === "finalStep") {
    return (
      <FinalStep
        {...step}
        setServerResponse={setServerResponse}
        serverResponse={serverResponse}
        step={step}
        handleChange={handleChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        nextStep={nextStep}
        backStep={backStep}
      />
    );
  }
  if (type && type === "radio")
    return (
      <RadioButtons
        options={step.enum}
        onClick={step.onChange}
        selected={step.value}
        {...step}
        listWidth="100%"
      ></RadioButtons>
    );
  if (type && type === "dropdown")
    return (
      <Dropdown
        justifyContent="start"
        showHeader={true}
        headerFontSize="16px"
        showArrow={true}
        maxHeight={4 * 49 + "px"}
        // boxShadow="0 0 5px rgba(255,255,255,0.5)"
        dropdownListMarginTop="20px"
        // background="#fff"
        border="none"
        headerBorder="1px solid #fff"
        headerHeight="49px"
        itemHeight="49px"
        textAlign="left"
        options={step.enum}
        handleStateChange={step.onChange}
        currSelection={step.value}
        {...step}
        handleMenuStateInternally={true}
        listWidth="100%"
      />
    );
  if (type && type === "iconSelection")
    return (
      <IconSelection
        options={step.enum}
        label={step.getLabel}
        {...step}
        onChange={
          step["multiSelect"]
            ? (optinId, currValue, steps, option) =>
              iconSelectionOnChange(step["id"], option)
            : (option) => iconSelectionOnChange(step["id"], option)
        }
      />
    );
  if (type === "text") return <TextStep {...step} />;
  if (type && type === "checkbox")
    return (
      <Checkbox
        checked={step.phoneCallRequested}
        onCheck={function () {
          step.onChange(step.checked);
        }}
        text={step.checkboxText}
      />
    );
  if (type && type === "textArea")
    return (
      <TextArea
        hideLabel={true}
        onChange={handleChange(`${name}`)}
        // checked={step.phoneCallRequested}
        // onCheck={() => step.onChange(step.checked)}
        // text={step.checkboxText}
        {...step}
      />
    );
  return (
    <InputField
      // onKeyPress={continueKey}
      type={type ? type : name === "email" ? "email" : "text"}
      onChange={handleChange(`${name}`)}
      name={name}
      placeholder={placeholder}
      marginBottom={"20px"}
      value={value}
      width="100%"
      onKeyDown={onKeyDown(`${name}`)}
      onKeyUp={onKeyUp}
      uppercaseOff={true}
    />
  );
};

export default Step;
