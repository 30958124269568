import React from 'react'

import { Wrapper } from './GridContainer.styles'

const GridContainer = ({ refId, children, ...props }) => {
    return (
        <Wrapper {...props} ref={refId}>{children}</Wrapper>
    )
}

export default GridContainer