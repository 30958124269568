import React from 'react'
import styled from 'styled-components'

const H1S = styled.h1`
font-size: 3rem;
margin-bottom: 10px;
text-align: ${({ textAlign }) => textAlign};
/* text-shadow: 0 0 5px #000; */
@media only screen and (max-width: 750px), screen and (max-device-width: 750px) {
    font-size: 1.8rem;
}
`;

const H1 = ({ children, ...props }) => <H1S {...props}>{children}</H1S>

export default H1