import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import styled from 'styled-components'
import Flexbox from './Flexbox'
import CheckmarkValidate from './CheckmarkValidate'
const PhoneInput2 = ({
    showValidationCheckmark, value,
    onCountryChange = () => { },
    setValue = () => { },
    placeholder = "Enter Phone Number",
    ...props
}) => {
    return (
        <Flexbox width="100%" position="relative">
            <PhoneInput
                international={true}
                // withCountryCallingCode
                addInternationalOption={true}
                countryCallingCodeEditable={false}
                country="US"
                defaultCountry="US"
                countrySelectProps={{ unicodeFlags: true }}
                placeholder={placeholder}
                value={value}
                onChange={setValue}
            // inputComponent={InputField}
            // onCountryChange={onCountryChange}
            ></PhoneInput>
            {showValidationCheckmark && <CheckmarkValidate {...props} />}
        </Flexbox>
    )
}

export default PhoneInput2